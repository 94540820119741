import React, { useEffect, useState } from "react";
import { SearchBar } from "../../Components/searchBar";
import { ZonesTable } from "./Table_Zoneview";
import { apiFake } from "../../Apis/configs/axiosConfig";
import { useLocation } from "react-router-dom";
import { ZonesDataByID } from "../../Components/helpers/zonesDataByID";

export const ZonesView = () => {
  return <ZonesTable />;
};

// import logo from './logo.svg';

import "./App.css";
import { useUser } from "./Auth/auth";
import { AppRouter } from "./routes/AppRouter";
import { Navbar } from "./Components/navbar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CookieConsent from "react-cookie-consent";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { apiUser } from "./Apis/configs/axiosConfig";
import { SpinnerCircular } from "spinners-react";

function App() {
  const { isLoggedIn, getAuthTokens } = useUser();
  const [isReady, setIsReady] = useState(false);

  const theme = createTheme({
    typography: {
      fontFamily: [
        "Helvetica",
        "Arial",
        "sans-serif",
        "Segoe UI Emoji",
        "BlinkMacSystemFont",
        "-apple-system",
        "Segoe UI",
        "Roboto",
        "Helvetica Neue",
        "Apple Color Emoji",
        "Segoe UI Symbol",
      ].join(","),
    },
  });

  useEffect(() => {
    if (isLoggedIn) {
      getAuthTokens().then((res) => {
        setIsReady(true);
      });

      apiUser.interceptors.request.use((config) => {
        return getAuthTokens()
          .then((data) => {
            config.headers.Authorization = `Bearer ${data.idToken}`;
            setIsReady(true);

            return Promise.resolve(config);
          })
          .catch((e) => {
            console.error(e);
            return Promise.resolve(config);
          });
      });
    }
  }, [isLoggedIn]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <CookieConsent
          location="bottom"
          buttonText="I AGREE"
          cookieName="cookieConsent"
          style={{
            background: "#042C66",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.5)",
            fontWeight: "bold",
            padding: 0,
            margin: 0,
          }}
          overlay
          buttonStyle={{
            background: "#43A047",
            color: "white",
            fontWeight: "bold",
            height: "100%",
            padding: "10px 20px",
            borderRadius: "4px",
          }}
          customContentAttributes={{}}
        >
          <Grid
            container
            justifyContent={{
              xs: "start",
              sm: "start",
              md: "space-between",
              lg: "space-between",
            }}
            alignItems="center"
          >
            <Grid item>
              This website relies on cookies to enhance the user experience.
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  marginTop: { xs: 2, sm: 2, md: 0, lg: 0 },
                }}
                onClick={() => {
                  window.open("https://krain.com", "_blank");
                }}
              >
                More Information
              </Button>
            </Grid>
          </Grid>
        </CookieConsent>
        {isLoggedIn && isReady ? (
          <Navbar>
            <AppRouter />
          </Navbar>
        ) : (
          <AppRouter />
        )}
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;

import {
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { apiUser } from "../../Apis/configs/axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import Draggable from "react-draggable";
import "react-resizable/css/styles.css";
import Modal from "@mui/material/Modal";
import PlaceIcon from "@mui/icons-material/Place";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "react-resizable/css/styles.css";
import "./styles.css";
import Swal from "sweetalert2";
import markerFlag from "./img/maps-and-flags.png";
import pointIcon from "./img/dot.png";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
  Polygon,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { point, rectangle } from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { useMapEvent } from "https://cdn.esm.sh/react-leaflet/hooks";
import { Icon } from "leaflet";
import { blue, blueGrey, green, grey, red } from "@mui/material/colors";
import PolygonMaker from "../../Components/DrawCanvas";
import sitemasterIcon from "./img/myController.png";
import markerIcon from "./img/Group 733.png";

import {
  ArrowBack,
  Delete,
  Edit,
  EditOff,
  FormatShapes,
  HourglassBottom,
  PlayArrow,
  Save,
} from "@mui/icons-material";
import "reactjs-popup/dist/index.css";
import Popper from "@mui/material/Popper";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from 'react-i18next';
import { t } from "i18next";

const styleRadioButton = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
  borderRadius: 6,
  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
  padding: 1,
  color: "black",
  fontSize: 16,
  textAlign: "center",
  maxHeight: "50%",
  overflow: "auto",
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const labelStyle = {
  fontSize: "22px",
  fontWeight: "bold",
  marginBottom: "10px",
  color: "#032C65",
};

const MapsWrapper = ({ selectedOption, onImgChange }) => {
  const location = useLocation();
  const mapCenterRef = useRef({ lat: 0, lng: 0 });
  const imgRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [loadingMap, setLoadingMap] = useState(true);
  const [rectangles, setRectangles] = useState([]);
  const [zonesF, setZonesF] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedZoneMarker, setSelectedZoneMarker] = useState(null);
  const [imageMarkers, setImageMarkers] = useState([]);
  const [selectedMarkerId, setSelectedMarkerId] = useState(null);
  const [selectedOptionSite, setSelectedOptionSite] = useState(null);
  const [newSiteplan, setnewSiteplan] = useState(false);
  const [opt, setopt] = useState(0);
  const [colors, setColors] = useState([
    "#42a5f5",
    "#e57373",
    "#ec407a",
    "#9c27b0",
    "#c62828",
    "#4a148c",
    "#009688",
    "#a5d6a7",
    "#cddc39",
    "#64dd17",
    "#fff59d",
    "#fdd835",
    "#ff9800",
    "#ff7043",
    "#6d4c41",
  ]);
  const [tool, setTool] = useState("Line");
  const [allZones, setAllZones] = useState([]);
  const [allPrograms, setAllPrograms] = useState({});
  const [] = useState();
  const [zone, setZone] = useState(null);

  const [mapData, setmapData] = useState(null);
  const [img, setImg] = useState(mapData ?? "");
  const [clicks, setClicks] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [zoneMapMarker, setzoneMapMarker] = useState(null);
  const [mapviewData, setmapviewData] = useState(null);
  const [shapes, setShapes] = useState([]);
  const [points, setPoints] = useState([]);
  const [selManualZone, setSelManualZone] = useState(null);
  const [manualRunOpen, setManualRunOpen] = useState(false);
  const [pushingManual, setPushingManual] = useState(false);
  const [runtimeHours, setRuntimeHours] = useState(0);
  const [runtimeMins, setRuntimeMins] = useState(0);
  const [polygons, setPolygons] = useState([]);
  const [dataShape, setdataShape] = useState(null);
  const [adviceMap, setadviceMap] = useState(0); // State = 1 -> cant use map view, cause dont have an address.
  const [drawing, setdrawing] = useState(false);
  const [makeShapes, setmakeShapes] = useState(false);
  const [openZoneInfo, setopenZoneInfo] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [addZoneBoundarie, setaddZoneBoundarie] = useState(false);
  const [markerinfo, setmarkerinfo] = useState(null);
  const [actualShape, setactualShape] = useState(null);
  const [addControllerPin, setaddControllerPin] = useState(false);
  const [ControllerPinSitePlan, setControllerPinSitePlan] = useState([]);
  const [removedBoundarie, setremovedBoundarie] = useState(false);
  const { t, i18n } = useTranslation();

  const closeModal = () => {
    setSelectedOptionSite(null);
    setmarkerinfo(null);
    setopenZoneInfo(false);
    setSelectedMarkerId(null);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingMap(true);
        const currentStatusResponse = await apiUser.get(
          `/controllers/${location.state.id}`
        );

        setAddress(currentStatusResponse.data.data.address);
        setAllZones(currentStatusResponse.data.data.zones);
        setAllPrograms(currentStatusResponse.data.data.programs);

        const latitude = Number(
          currentStatusResponse.data.data.address.latitude
        );
        const longitude = Number(
          currentStatusResponse.data.data.address.longitude
        );
        if (
          !isNaN(latitude) &&
          !isNaN(longitude) &&
          latitude !== 0 &&
          longitude !== 0
        ) {
          setadviceMap(3);
          setMapCenter({
            lat: latitude,
            lng: longitude,
            text: "Controller Address",
          });
          mapCenterRef.current = { lat: latitude, lng: longitude };
        } else {
          
          show_Advice();
          return;
        }

        const zone_f = currentStatusResponse.data.data.zones;
        if (zone_f) {
          const zones_to_filter = zone_f.filter(
            (item) => item.decoder !== "000000"
          );
          setZonesF(zones_to_filter);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingMap(false);
      }
    };

    const fetchMapData = async () => {
      try {
        const request = await apiUser.get(
          `/controllers/${location.state.id}/map_data`
        );
        setmapData(request.data.data.map_data.img_based);
        setmapviewData(request.data.data.map_data.geo_based);

        if (request.data.data.map_data.geo_based !== null) {
          setMarkers(request.data.data.map_data.geo_based.decoder_pins);
        }

        let imgData = request.data.data.map_data.img_based;
        if (imgData !== null) {
          imgData = request.data.data.map_data.img_based.image_data;
          setImg(`${imgData}`);
          onImgChange(`${imgData}`);
        }
      } catch (error) {
        console.error(error, "ERR");
      }
    };

    fetchData();
    fetchMapData();
  }, [location.state.id]);

  useEffect(() => {

    showMapData();
  }, [mapData, mapviewData]);
  const showMapData = () => {
    // site plan data

    if (
      mapData !== null &&
      mapData.decoder_pins !== null &&
      mapData.zone_boundaries !== null
    ) {
      // proccesing lat and lng points of decoder pins to x , y  and new ID


      const updatedMapDataDecoder = mapData.decoder_pins.map((item) => {
        const newPosition = {
          x: parseFloat(item.position.lat),
          y: parseFloat(item.position.lng),
        };

        return {
          ...item,
          id: Math.floor(Math.random() * 1000) + 1,
          position: newPosition,
        };
      });
      if(mapData.controllerPin){
        mapData.controller_pin = {
          ...mapData.controller_pin,
          position: {
            x: Number(mapData.controller_pin.lat),
            y: Number(mapData.controller_pin.lng),
          },
          id: Math.floor(Math.random() * 1000) + 1,
        };

        setControllerPinSitePlan([mapData.controller_pin]);

      }
      else{

      }
    

      // Proccesing lat and lng points of zone_boundaries without names.
      mapData.zone_boundaries.forEach((item, index) => {
        if (item.shape_points) {
          item.shape_points = item.shape_points.map((point) => [
            parseFloat(point.lat),
            parseFloat(point.lng),
          ]);
        }
        const zoneprops = zonesF.filter(
          (zone) => zone.decoder === item.zone_ref
        );
        mapData.zone_boundaries[index] = {
          ...item,
          zoneprops: zoneprops,
        };
      });
      setPolygons(mapData.zone_boundaries);
      setImageMarkers(updatedMapDataDecoder);
    } else {
    }
    // map view data
    if (
      mapviewData !== null &&
      mapviewData.decoder_pins !== null &&
      mapviewData.zone_boundaries !== null
    ) {
      // console.log(mapviewData);

      // proccesing lat and lng points of decoder pins in map view data

      const updatedMapDataDecoder = mapviewData.decoder_pins.map((item) => {
        const newPosition = {
          lat: parseFloat(item.position.lat),
          lng: parseFloat(item.position.lng),
        };

        return {
          ...item,
          id: Math.floor(Math.random() * 1000) + 1,
          position: newPosition,
        };
      });

      // proccesing lat and lng points of zone boundaries in map view data
      mapviewData.zone_boundaries.forEach((item) => {
        if (item.shape_points) {
          item.shape_points = item.shape_points.map((point) => [
            parseFloat(point.lat),
            parseFloat(point.lng),
          ]);
        }
      });
      setShapes(mapviewData.zone_boundaries);
      setMarkers(updatedMapDataDecoder);
      // console.log(mapviewData.decoder_pins);
    }
  };

  //------------------> OPEN STREET FUNCTIONS <-----------------//
  const onClick = (e) => {
    if (zoneMapMarker) {
      const mark = {
        position: { lat: e.latlng.lat, lng: e.latlng.lng },
        zone_ref: zoneMapMarker.zone_ref || "",
        zone_name: zoneMapMarker.zone_name || "",
      };
      setClicks([...clicks, e.latLng]);
      // filter out existing instances of a pin pointing to the same zone_ref
      setMarkers([
        ...markers.filter((item) => item.zone_ref !== mark.zone_ref),
        mark,
      ]);
      setzoneMapMarker(null);
      setSelectedOptionSite(null);
      setmakeShapes(true);
    } else {
      const newPoint = [e.latlng.lat, e.latlng.lng];
      setPoints([...points, newPoint]);
    }
  };
  const MapEventsHandler = () => {
    useMapEvents({
      click: (e) => onClick(e),
    });
  };
  const MarkerPopup = ({ zoneRef, zoneMarker }) => {
    // const zoneData = allZones.find((item) => item.decoder === zoneRef);
    const zoneIndex = allZones.findIndex((item) => item.decoder === zoneRef);

    const zoneData = zoneIndex >= 0 ? allZones[zoneIndex] : {};

    const programsAffected = Object.keys(allPrograms).filter((progLetter) => {
      const zoneRt = allPrograms[progLetter].run_times[zoneIndex] || {};

      return zoneRt.hour > 0 || zoneRt.minutes > 0 || zoneRt.seconds > 0;
    });
    return (
      <>
        {selectedOption === 1 ? (
          <Popup
            closeOnClick={true}
            close={closeModal}
            closeButton={true}
            maxWidth={window.screen.width * 0.3}
            minWidth={350}
          >
            <List>
              {/* <CloseIcon
                    size="large"
                    color="primary"
                    sx={{ left: 352, cursor: "pointer" , position:'absolute' , top:-12 , zIndex:0  }}
                    onClick={closeModal}
                  /> */}
              <ListItem
                secondaryAction={
                  <IconButton
                    size="large"
                    color="primary"
                    onClick={() => {
                      setManualRunOpen(true);
                      setSelManualZone({ ...zoneData, number: zoneIndex + 1 });
                    }}
                  >
                    <PlayArrow fontSize="large" />
                    <p style={{ fontSize: 16 }}>{t(`Run zone`)}</p>
                  </IconButton>
                }
                dense
              >
                <ListItemText
                  primary={
                    zoneData.zone_name
                      ? zoneData.zone_name
                      : t(`Zone`) + ` ${zoneIndex + 1}`
                  }
                  primaryTypographyProps={{ fontWeight: "bold", lineHeight: 1 }}
                  secondary={zoneData.decoder}
                  secondaryTypographyProps={{ lineHeight: 1 }}
                />
              </ListItem>
              {programsAffected.length > 0 && (
                <Box
                  sx={{
                    borderRadius: 3,
                    border: `1px solid ${grey[300]}`,
                    backgroundColor: grey[200],
                    maxHeight: 250,
                    overflowY: "scroll",
                  }}
                >
                  {programsAffected.map((progLetter, index) => {
                    const zoneRt =
                      allPrograms[progLetter].run_times[zoneIndex] || {};

                    const hrs = zoneRt.hour.toString().padStart(2, "0");
                    const mins = zoneRt.minutes.toString().padStart(2, "0");
                    const secs = zoneRt.seconds.toString().padStart(2, "0");

                    const needsDivider = index < programsAffected.length - 1;

                    return (
                      <ListItem
                        key={`p-${progLetter}-rt-${zoneIndex}`}
                        divider={needsDivider}
                        dense
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <HourglassBottom />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={t(`Program`) +` ${progLetter.toUpperCase()}`}
                          primaryTypographyProps={{
                            fontWeight: "bold",
                            lineHeight: 1,
                          }}
                          secondary={`${hrs}:${mins}:${secs}`}
                        />
                      </ListItem>
                    );
                  })}
                </Box>
              )}
              <ListItem>
                {/* <IconButton
                  title="Remove  boundaries"
                  sx={{ color: red[900] , fontSize:16 ,  border:'1px solid ' , borderColor:red[900] ,   borderRadius:2 , position:'relative', left:120 , top:10 }}
                  onClick={() => {
                    Swal.fire({
                      title: "Remove alert",
                      text: `Are you sure to remove ${zoneData.zone_name} ?`,
                      icon: "question",
                      showConfirmButton: true,
                      confirmButtonColor: "#ffae00",
                      confirmButtonText: " Remove it",
                      showDenyButton: true,
                      denyButtonText: "Cancel",
                    }).then((data) => {
                      if (data.isConfirmed) {
                        removeFromMap(zoneRef, zoneMarker);
                      } else if (data.isDenied) {
                      }
                    });
                  }}
                >
                  <Delete />Remove zone boundaries
                </IconButton> */}
                <Button
                  startIcon={<Delete />}
                  sx={{
                    color: "#BF0021",
                    fontSize: 14,
                    borderColor: red[900],
                    borderRadius: 2,
                    position: "relative",
                    left: 110,
                    top: 25,
                  }}
                  onClick={() => {
                    Swal.fire({
                      title: t(`Remove`) +` ${
                        zoneMarker ? t("Decoder pin") : t("Zone boundary")
                      }`,
                      text: t(`Are you sure to remove`)+ ` ${zoneData.zone_name} ${
                        zoneMarker ? t("Decoder pin") : t("Zone boundary")
                      } ?`,
                      icon: "question",
                      showConfirmButton: true,
                      confirmButtonColor: "#BF0021",
                      confirmButtonText: t(`Remove`) + ` ${
                        zoneMarker ? t("Decoder pin") : t("Zone boundary")
                      }  `,
                      showDenyButton: true,
                      denyButtonText: t("Cancel"),
                      denyButtonColor: "#032C65",
                    }).then((data) => {
                      if (data.isConfirmed) {
                        removeFromMap(zoneRef, zoneMarker);
                      } else if (data.isDenied) {
                      }
                    });
                  }}
                >
                  {t(`Remove zone boundaries`)}
                </Button>
                {!zoneMarker && (
                  <Button
                    startIcon={<Edit />}
                    sx={{
                      color: "#032C65",
                      fontSize: 14,
                      borderRadius: 2,
                      position: "absolute",
                      left: 0,
                      top: 35,
                    }}
                    onClick={() => {
                      setopt(3);
                      setactualShape(zoneData);
                      showBoundaries(10);
                    }}
                  >
                    Edit
                  </Button>
                )}
              </ListItem>
            </List>
          </Popup>
        ) : (
          <Grid item container xs={12} justifyContent="center">
            <Popper
              sx={{ minWidth: 350, zIndex: 1 }}
              anchorEl={anchorEl ? anchorEl : null}
              open={openZoneInfo}
              close={closeModal}
              placement="center"
              style={{ position: "absolute", left: "45%", top: "40%" }}
            >
              <Paper>
                <List>
                  <Grid container justifyContent="center">
                    <CloseIcon
                      size="large"
                      color="primary"
                      sx={{
                        left: 145,
                        cursor: "pointer",
                        position: "relative",
                      }}
                      onClick={closeModal}
                    />
                  </Grid>

                  <ListItem
                    sx={{ marginTop: "5%" }}
                    secondaryAction={
                      <IconButton
                        size="medium"
                        color="primary"
                        onClick={() => {
                          setManualRunOpen(true);
                          setSelManualZone({
                            ...zoneData,
                            number: zoneIndex + 1,
                          });
                        }}
                      >
                        <PlayArrow fontSize="large" />
                        <Typography>{t(`Run zone`)} </Typography>
                      </IconButton>
                    }
                    dense
                  >
                    <ListItemText
                      primary={
                        zoneData.zone_name
                          ? zoneData.zone_name
                          : `Zone ${zoneIndex + 1}`
                      }
                      primaryTypographyProps={{
                        fontWeight: "bold",
                        lineHeight: 1,
                      }}
                      secondary={zoneData.decoder}
                      secondaryTypographyProps={{ lineHeight: 1 }}
                    />
                  </ListItem>
                  {programsAffected.length > 0 && (
                    <Box
                      sx={{
                        borderRadius: 3,
                        border: `1px solid ${grey[300]}`,
                        backgroundColor: grey[200],
                        maxHeight: 250,
                        overflowY: "scroll",
                      }}
                    >
                      {programsAffected.map((progLetter, index) => {
                        const zoneRt =
                          allPrograms[progLetter].run_times[zoneIndex] || {};

                        const hrs = zoneRt.hour.toString().padStart(2, "0");
                        const mins = zoneRt.minutes.toString().padStart(2, "0");
                        const secs = zoneRt.seconds.toString().padStart(2, "0");

                        const needsDivider =
                          index < programsAffected.length - 1;

                        return (
                          <ListItem
                            key={`p-${progLetter}-rt-${zoneIndex}`}
                            divider={needsDivider}
                            dense
                          >
                            <ListItemAvatar>
                              <Avatar>
                                <HourglassBottom />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={t(`Program`) +`  ${progLetter.toUpperCase()}`}
                              primaryTypographyProps={{
                                fontWeight: "bold",
                                lineHeight: 1,
                              }}
                              secondary={`${hrs}:${mins}:${secs}`}
                            />
                          </ListItem>
                        );
                      })}
                    </Box>
                  )}
                  <ListItem>
                    {/* <Button startIcon={<Delete/>} style={{color: 'white' , backgroundColor:'#BF0021'  }}  >Remove zone boundaries</Button> */}

                    <IconButton
                      title={t("Remove zone boundaries")}
                      sx={{
                        color: "#BF0021",
                        fontSize: 16,
                        borderColor: red[900],
                        borderRadius: 2,
                        position: "relative",
                        left: 100,
                        top: 10,
                      }}
                      onClick={() => {
                        // console.log("remove item");
                        // removeMapViewBoundaries(point);
                        // if (zoneMarker) {
                        //   console.log("Marker deletion");
                        // } else {
                        //   console.log("Boundary deletion");
                        // }

                        Swal.fire({
                          title: t(`Remove`) +` ${
                            markerinfo ? t("Decoder pin") : t("Zone boundary")
                          }`,
                          text: t(`Are you sure to remove`) + ` ${zoneData.zone_name} ${
                            markerinfo ? t("Decoder pin") : t("Zone boundary")
                          } ?`,
                          icon: "question",
                          showConfirmButton: true,
                          confirmButtonColor: "#BF0021",
                          confirmButtonText: t(`Remove`) +`  ${
                            markerinfo ? t("Decoder pin") : t("Zone boundary")
                          } `,
                          showDenyButton: true,
                          denyButtonText: t("Cancel"),
                          denyButtonColor: "#032C65",
                        }).then((data) => {
                          if (data.isConfirmed) {
                            setremovedBoundarie(true);
                            removeFromSitePlan(zoneRef);
                            closeModal();
                          } else if (data.isDenied) {
                          }
                        });
                      }}
                    >
                      <Delete />
                      {t(`Remove zone boundaries`)}
                    </IconButton>
                    {dataShape !== null && (
                      <IconButton
                        title={t("Edit")}
                        sx={{
                          color: "#032C65",
                          fontSize: 16,
                          borderColor: red[900],
                          borderRadius: 2,
                          position: "absolute",
                          left: 10,
                          top: 20,
                        }}
                        onClick={() => {
                          closeModal();
                          setopt(1);
                          showBoundaries(5);
                        }}
                      >
                        <Edit />
                        {t(`Edit`)}
                      </IconButton>
                    )}
                  </ListItem>
                </List>
              </Paper>
            </Popper>
          </Grid>
        )}
      </>
    );
  };
  const markerDrag = (id, e, ui) => {
    // console.log(e.target._latlng.lat);
    const updatedMarkers = markers.map((marker) =>
      marker.id === id
        ? {
            ...marker,
            position: {
              lat: e.target._latlng.lat,
              lng: e.target._latlng.lng,
            },
          }
        : marker
    );

    // console.log(updatedMarkers);

    setMarkers(updatedMarkers);
  };

  const saveShape = (color, zone) => {
    const newShape = {
      id: shapes.length + 1,
      position: { x: 0, y: 0 },
      color: color || "black",
      shape_points: points,
      zone_ref: zone,
    };

    setShapes([
      ...shapes.filter((item) => item.zone_ref !== newShape.zone_ref),
      newShape,
    ]);
    setPoints([]);
    setopt(0);
  };

  const removeFromMap = (zoneRef, isMarker) => {
    if (isMarker) {
      const updatedMarker = markers.filter((item) => item.zone_ref !== zoneRef);
      setMarkers([...updatedMarker]);
    } else {
      const updatedShapes = shapes.filter((item) => item.zone_ref !== zoneRef);

      setShapes([...updatedShapes]);
    }
  };

  const removeFromSitePlan = (zoneRef) => {
    setremovedBoundarie(true);

    if (markerinfo !== null) {
      const updatedImageMarker = imageMarkers.filter(
        (item) => item.zone_ref !== zoneRef
      );
      setImageMarkers([...updatedImageMarker]);
      console.log(imageMarkers);
    }
    if (dataShape !== null) {
      console.log('1')
      const updtpolygons = polygons.filter((item) => item.zone_ref !== zoneRef);

      setPolygons(updtpolygons);
      console.log(updtpolygons)
      console.log(polygons)

    }
  };

  //--------------------------------------------------------------//

  //------------------> HANDLE IMGS FUNCTIONS <-----------------//

  const handleIMG = (e) => {
    
      setPolygons([]);
      setImageMarkers([]);
      setControllerPinSitePlan([]);
      setImg("");
      setnewSiteplan(true);
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      setImg(imageUrl);
      onImgChange(imageUrl);
    
  };

  const loadImageAsBlob = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error("Error :", error);
      throw error;
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      if (file instanceof Blob) {
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      } else {
      }
    });
  };

  //--------------------------------------------------------------//

  useEffect(() => {
    const timeout = setTimeout(() => setTool("Polygon"), 50);
    return () => clearTimeout(timeout);
  }, []);

  const markerSite = (zone, option) => {
    if (option === 4) {
      setSelectedZoneMarker({
        zone_ref: zone.decoder,
        zone_name: zone.zone_name,
      });
    }

    if (option === 9) {
      setzoneMapMarker({
        zone_ref: zone.decoder,
        zone_name: zone.zone_name,
      });
    }

    setOpen(false);
  };

  const handleRuntimeHours = (e) => {
    // console.log(e.target.value);
    setRuntimeHours(e.target.value);
  };

  const handleRuntimeMinutes = (e) => {
    // console.log(e.target.value);
    setRuntimeMins(e.target.value);
  };

  const startZone = async (zoneNumber) => {
    const totalSeconds = runtimeHours * 3600 + runtimeMins * 60;

    try {
      setPushingManual(true);

      const resp = await apiUser.put(
        `/controllers/${location.state.id}/update_schedule/manual_operation`,
        {
          station: zoneNumber,
          time: totalSeconds,
        }
      );

      if (resp.data.success) {
        Swal.fire({
          title: t("Manual Run Sent"),
          text: t("Irrigation will start shortly!"),
          icon: "success",
          showConfirmButton: false,
          timer: 5000,
        });
      }

      setRuntimeHours(0);
      setRuntimeMins(0);
      setManualRunOpen(false);
      setSelManualZone(null);

      // console.log(resp);
    } catch (e) {
      console.error(e);
    } finally {
      setManualRunOpen(false);
      setPushingManual(false);
    }
  };

  const color_circles = (option) => {
    const selectColor = (color) => {
      if (option === 0) {
        setremovedBoundarie(false);
        setdrawing(true);
        setdataShape({ color, zone });
        setZone(null);
        closebox();
      }
      if (option === 1) {
        setremovedBoundarie(false);

        const poly_updated = polygons.map((item) =>
          item.zone_ref == dataShape.zone_ref
            ? {
                ...item,
                color: color,
              }
            : item
        );

        setPolygons(poly_updated);
        console.log("Color circles opt 1");
        setopt(0);
        closebox();
      }

      if (option === 2) {
        saveShape(color, zone.decoder);
        setmakeShapes(false);
        setZone(null);

        closebox();
      }
      if (option === 3) {
        console.log(actualShape);
        const shape_update = shapes.map((item) =>
          item.zone_ref === actualShape.decoder
            ? {
                ...item,
                color: color,
              }
            : item ,
        

            
        );
        console.log(shape_update)
        console.log(shapes)
        console.log(useMapEvents)
        console.log(L)

        setShapes([...shape_update]);
        console.log(shapes)

        setopt(0);
        closebox();
      }
    };
    const selectZone = (zones) => {
      setZone(zones);
    };

    return (
      <>
        {opt == 0 && (
          <Grid
            item
            container
            xs={11}
            md={9}
            justifyContent="center"
            paddingY={5}
          >
            <Grid container justifyContent="center" marginBottom={3}>
              <Typography
                style={{ fontWeight: "bold", color: "#032C65" }}
                textAlign="center"
                variant="h5"
              >
               {t(`Select a zone to assign boundaries`)}
              </Typography>
            </Grid>

            <Grid item container xs={11} sm={10} md={9} justifyContent="center">
              {zonesF
                ? zonesF.map((item, index) => {
                    if (item) {
                      const isChecked = imageMarkers.some(
                        (img) => img.zone_ref === item.decoder
                      );

                      const isPolygon = polygons.some(
                        (poly) => poly.zone_ref === item.decoder
                      );

                      return (
                        <div
                          key={index}
                          onClick={() => {
                            if (isChecked) {
                              selectZone(item);
                            } else {
                              alert(
                                t(`Need to select a decoder already present in the map`)
                              );
                              selectZone(null);
                            }

                            if (isPolygon) {
                              alert(t(`Its already a zone boundarie.`));
                              selectZone(null);
                            }
                          }}
                          className={`  card-zone-maps ${
                            isChecked ? "checked" : ""
                          }`}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {item.zone_name} Zone {index + 1}
                          </span>
                        </div>
                      );
                    }
                  })
                : ""}
            </Grid>
            {zone && (
              <Grid
                item
                container
                xs={11}
                sm={10}
                md={9}
                justifyContent="center"
              >
                <Typography
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    top: "10%",
                    marginTop: "10%",
                    paddingBottom: "5%",
                  }}
                >
                  {t(`Select the color`)}
                </Typography>
                <Grid item container xs={12} justifyContent="space-evenly">
                  {colors.map((color, index) => (
                    <Avatar
                      sx={{
                        bgcolor: color,
                        width: 70,
                        height: 70,
                        margin: 0.3,
                        border: `1px solid ${blueGrey[800]}`,
                        boxShadow: 3,
                      }}
                      onClick={() => selectColor(color)}
                    >
                      {" "}
                    </Avatar>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        )}

        {opt == 2 && (
          <Grid
            item
            container
            xs={11}
            md={9}
            justifyContent="center"
            paddingY={5}
          >
            <Grid container justifyContent="center" marginBottom={3}>
              <Typography
                style={{ fontWeight: "bold", color: "#032C65" }}
                textAlign="center"
                variant="h5"
              >
                {t(`Select a zone to assign boundaries`)}
              </Typography>
            </Grid>

            <Grid item container xs={11} sm={10} md={9} justifyContent="center">
              {zonesF
                ? zonesF.map((item, index) => {
                    if (item) {
                      const isChecked = markers.some(
                        (img) => img.zone_ref === item.decoder
                      );

                      const isShape = shapes.some(
                        (shape) => shape.zone_ref === item.decoder
                      );

                      return (
                        <div
                          key={index}
                          onClick={() => {
                            if (isChecked) {
                              selectZone(item);
                            } else {
                              alert(
                                t(`Need to select a decoder already present in the map`)
                              );
                              selectZone(null);
                            }
                            if (isShape) {
                              alert(t(`Zone has a boundary already!`));
                              selectZone(null);
                            }
                          }}
                          className={`  card-zone-maps ${
                            isChecked ? "checked" : ""
                          }`}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {item.zone_name} {t(`Zone`)} {index + 1}
                          </span>
                        </div>
                      );
                    }
                  })
                : ""}
            </Grid>
            {zone && (
              <Grid
                item
                container
                xs={11}
                sm={10}
                md={9}
                justifyContent="center"
              >
                <Typography
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    top: "10%",
                    marginTop: "10%",
                    paddingBottom: "5%",
                  }}
                >
                  {t(`Select the color`)}
                </Typography>
                <Grid item container xs={12} justifyContent="space-evenly">
                  {colors.map((color, index) => (
                    <Avatar
                      sx={{
                        bgcolor: color,
                        width: 70,
                        height: 70,
                        margin: 0.3,
                        border: `1px solid ${blueGrey[800]}`,
                        boxShadow: 3,
                      }}
                      onClick={() => selectColor(color)}
                    >
                      {" "}
                    </Avatar>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        )}

        {opt == 1 && (
          <Grid item container xs={11} sm={10} md={9} justifyContent="center">
            <Typography
              style={{
                color: "black",
                fontWeight: "bold",
                top: "10%",
                marginTop: "10%",
                paddingBottom: "5%",
              }}
            >
              {t(`Select the color`)}
            </Typography>
            <Grid item container xs={12} justifyContent="space-evenly">
              {colors.map((color, index) => (
                <Avatar
                  sx={{
                    bgcolor: color,
                    width: 70,
                    height: 70,
                    margin: 0.3,
                    border: `1px solid ${blueGrey[800]}`,
                    boxShadow: 3,
                  }}
                  onClick={() => selectColor(color)}
                >
                  {" "}
                </Avatar>
              ))}
            </Grid>
          </Grid>
        )}

        {opt == 3 && (
          <Grid item container xs={11} sm={10} md={9} justifyContent="center">
            <Typography
              style={{
                color: "black",
                fontWeight: "bold",
                top: "10%",
                marginTop: "10%",
                paddingBottom: "5%",
              }}
            >
              {t(`Select the color`)}
            </Typography>
            <Grid item container xs={12} justifyContent="space-evenly">
              {colors.map((color, index) => (
                <Avatar
                  sx={{
                    bgcolor: color,
                    width: 70,
                    height: 70,
                    margin: 0.3,
                    border: `1px solid ${blueGrey[800]}`,
                    boxShadow: 3,
                  }}
                  onClick={() => selectColor(color)}
                >
                  {" "}
                </Avatar>
              ))}
            </Grid>
          </Grid>
        )}
      </>
    );
  };
  useEffect(() => {
    
    console.log("Shapes actualizado:", shapes);
    
   
    
  }, [shapes]); 
  

  const closebox = () => setOpen(false);

  const showBoundaries = (option) => {
    if (option === 9) {
      setSelectedOptionSite(option);
      setOpen(true);
    }
    if (option === 4) {
      setSelectedOptionSite(option);

      setOpen(true);
    }

    if (option === 10) {
      setSelectedOptionSite(option);
      setOpen(true);
    }
    if (option === 5) {
      setSelectedOptionSite(option);
      setOpen(true);
    }
    // TODO: Come back to this (ask Moises what's the deal here)
    // if (option) {
    //   setSelectedOptionSite(option);

    //   setopt(0);
    //   setOpen(true);
    // }
  };

  const handleMarkerDrag = (id, _e, ui) => {
    if (selectedMarkerId === id) {
      const updatedMarkers = imageMarkers.map((marker) =>
        marker.id === id
          ? {
              ...marker,
              position: {
                x: ui.lastX,
                y: ui.lastY,
              },
            }
          : marker
      );
      setImageMarkers(updatedMarkers);
    }
  };

  const convertNumbersToStrings = (obj) => {
    if (obj !== null && typeof obj === "object") {
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === "number") {
          obj[key] = String(obj[key]);
        } else if (typeof obj[key] === "object") {
          obj[key] = convertNumbersToStrings(obj[key]);
        }
      });
    }
    return obj;
  };

  //---------------------------------> SAVE BUTTONS <----------------------------------//

  //---------------------> SITE PLAN SAVE BUTTON <--------------------------//
  const proccess_polygons = (polygons) => {
    console.log(polygons)
    const updated_polygons = polygons.map((polygon) => {
      console.log(polygon.shape_points[0].lat )

        


      if (
        polygon.shape_points &&
        polygon.shape_points.length > 0 &&
        !polygon.shape_points[0].lat 
    
      ) {
        const normalizedPoints = polygon.shape_points.map((point) => ({
          lat: point[0],
          lng: point[1],
        }));

        return { ...polygon, shape_points: normalizedPoints };
      } else {
        return polygon;
      }
    });
    return updated_polygons;
  };

  const save = async () => {
    setIsLoading(true);

    let img64 = "";
    try {
      const imgBlob = await loadImageAsBlob(img);

      img64 = await convertToBase64(imgBlob);
    } catch (error) {
      console.error("Error:", error);
    }

    const newImageMarkers = imageMarkers.map((item) => {
      const newPosition = {
        lat: String(item.position.x),
        lng: String(item.position.y),
      };

      delete item.id;

      return {
        ...item,
        position: newPosition,
      };
    });

    const newControllerPin = ControllerPinSitePlan.map((item) => {
      let newPosition = {
        lat: String(item.position.x),
        lng: String(item.position.y),
      };
      delete item.id;
      delete item.lat;
      delete item.lng;
      return {
        ...item,
        position: newPosition,
      };
    });

    console.log(polygons);
    polygons.forEach((item) => {
      delete item.id;
      delete item.size;
      delete item.zone_name;
      delete item.position;
      delete item.zoneprops;
    });



    

    const updated_polygons = proccess_polygons(polygons);
    console.log(updated_polygons)
    let poly_to_send = updated_polygons.filter((item)=> item.shape_points[0].lat !== undefined)

    convertNumbersToStrings(newImageMarkers);








    convertNumbersToStrings(updated_polygons);

let body = {}
    
    if(newControllerPin.length>0){
       body = {
        img_based: {
          controller_pin: {
            lng: newControllerPin[0].position.lng,
            lat: newControllerPin[0].position.lat ,
          },
          decoder_pins: newImageMarkers,
          zone_boundaries: poly_to_send,
          image_data: img64,
        },
      };

    }
    else{
       body = {
        img_based: {
         
          decoder_pins: newImageMarkers,
          zone_boundaries: poly_to_send,
          image_data: img64,
        },
      };
    }
  
    console.log(body)
    try {
      const request = await apiUser.put(
        `/controllers/${location.state.id}/map_data`,
        body
      );
      console.log(updated_polygons);
      console.log(polygons);
      if (request.data.success === true) {
        Swal.fire({
          title: t("Save site plan"),
          text: t("Site plan updated"),
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        console.log(updated_polygons);
        console.log(polygons);
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      Swal.fire({
        title: t("Save site plan"),
        text: t("Failed to save the changes"),
        icon: "error",
      });
      setIsLoading(false);
    }
  };
  //---------------------> MAP  VIEW SAVE BUTTON <--------------------------//
  const show_Advice = () => {
    if (address == null) {
      Swal.fire({
        title: t("NO ADDRESS FOUND"),
        text: t("Configure your address to use this feature"),
        icon: "info",
        confirmButtonColor: "#032C65",
        confirmButtonText: t("Confirm"),
      });
      setadviceMap(1);
    }
  };
  const saveMapView = async () => {
    convertNumbersToStrings(markers);
    convertNumbersToStrings(shapes);

    // --> Removing atributes to the api <-- //

    markers.forEach((item) => {
      delete item.id;
    });

    shapes.forEach((item) => {
      delete item.id;
      delete item.position;
      delete item.size;

      if (item.shape_points) {
        item.shape_points = item.shape_points.map((point) => {
          return {
            lat: point[0],
            lng: point[1],
          };
        });
      }
    
    });
    //-------------------------------------//

    const body = {
      geo_based: {
        controller_pin: {
          lng: String(address.longitude),
          lat: String(address.latitude),
        },
        decoder_pins: markers,
        zone_boundaries: shapes,
      },
    };
    try {
      setIsLoading(true);
      const request = await apiUser.put(
        `/controllers/${location.state.id}/map_data`,
        body
      );

      if (request.data.success === true) {
        Swal.fire({
          title: t("Save Map"),
          text: t(`Changes made to your map`),
          timer: 1500,
          timerProgressBar: true,

          icon: "success",
          confirmButtonColor: "#032C65",
          confirmButtonText: t("Confirm"),
          background: "#f7f8fa",
        }).then(() => {
          shapes.forEach((item) => {
            if (item.shape_points) {
              item.shape_points = item.shape_points.map((point) => [
                parseFloat(point.lat),
                parseFloat(point.lng),
              ]);
            }
            console.log(item.shape_points);
          });

          // console.log(typeof location.state.id)
          // navigate(`/mycontrollers`)
        });
      }
    } catch (e) {
      return Swal.fire({
        title: t("Save Map"),
        text: t("Failed to save your changes. Please try again."),
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  let DefaultIcon = L.icon({
    iconUrl: markerIcon,
    shadowUrl: iconShadow,
    iconSize: [50, 50],
  });

  const customIcon = L.icon({
    iconUrl: sitemasterIcon,
    iconSize: [120, 90],
  });
  let polygonsPoint = L.icon({
    iconUrl: pointIcon,
    iconSize: [22, 21],
  });
  L.Marker.prototype.options.icon = DefaultIcon;
  //--------------------------------------------------------------------------------------//
  const getpolyref = (value) => {
    if (value && dataShape !== null && dataShape.color !== "") {
      const isDuplicate = polygons.some((polygon) => polygon.id === value._id);
      if (!isDuplicate) {
        const points = value.attrs.points;

        const shapePoints = [];
        if (points !== undefined) {
          for (let i = 0; i < points.length; i += 2) {
            shapePoints.push([points[i], points[i + 1]]);
          }

          if (dataShape.zone) {
            setPolygons((prevPolygons) => [
              ...prevPolygons,
              {
                id: value._id,
                shape_points: shapePoints,
                color: dataShape.color ? dataShape.color : "red",
                zone_name: dataShape.zone.zone_name
                  ? dataShape.zone.zone_name
                  : "N/A",
                zone_ref: dataShape.zone.decoder
                  ? dataShape.zone.decoder
                  : "N/A",
                position: value.position,
              },
            ]);
          }
          if (dataShape.zone_ref && removedBoundarie == false) {
            setPolygons((prevPolygons) => [
              ...prevPolygons,
              {
                id: value._id,
                shape_points: shapePoints,
                color: dataShape.color ? dataShape.color : "red",
                zone_name: dataShape.zone_name ? dataShape.zone_name : "N/A",
                zone_ref: dataShape.zone_ref ? dataShape.zone_ref : "N/A",
                position: value.position,
              },
            ]);
          }
        } else {
        }
      }
    }
  };
  const dialogAction = (poly) => {
    console.log(poly);

    setdataShape(poly);
    setmarkerinfo(null);
    setopenZoneInfo(true);
  };
  const update_poly_position = (poly) => {
    console.log(poly);
    setPolygons(poly);
  };

  const poly_completed = () => {
    setdrawing(false);
    setSelectedOptionSite(null);

    setaddZoneBoundarie(false);
  };

  const handleImageClick = (e) => {
    if (selectedZoneMarker) {
      const newMarker = {
        ...selectedZoneMarker,
        id: Date.now(),
        position: {
          x: e.evt.offsetX,
          y: e.evt.offsetY,
        },
      };

      setImageMarkers([...imageMarkers, newMarker]);
      setaddZoneBoundarie(true);
      setSelectedZoneMarker(null);
      setSelectedOptionSite(null);
    }
    if (addControllerPin == true) {
      console.log(e);
      if (e) {
        const controllerPin = {
          id: Date.now(),
          position: {
            x: e.evt.offsetX,
            y: e.evt.offsetY,
          },
        };
        setControllerPinSitePlan([...ControllerPinSitePlan, controllerPin]);
        setaddControllerPin(false);
      } else {
      }
    }
  };

  const showInfo_siteplan = (event, marker) => {
    setmarkerinfo(marker);
    setdataShape(null);
    setSelectedMarkerId(marker.id);
    setAnchorEl(event.currentTarget);
    setopenZoneInfo(true);
  };

  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  useEffect(() => {
    if (containerRef.current) {
      const width = containerRef.current.offsetWidth;
      const height = containerRef.current.offsetHeight;
      setContainerWidth(width);
      setContainerHeight(height);
    }
  }, []);

  return (
    <>
      {/* BUTTONS OF Map view */}
      {selectedOption === 1 && (
        <>
          <Grid
            item
            container
            xs={12}
            paddingY={2}
            justifyContent={{ xs: "start", md: "space-between" }}
          >
            <Grid
              item
              container
              xs={12}
              md={8}
              spacing={2}
              justifyContent="start"
            >
              {opt !== 2 && (
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => {
                      if (selectedOptionSite !== 9 && adviceMap == 3) {
                        showBoundaries(9);
                      } else {
                        setzoneMapMarker(null);
                        setSelectedOptionSite(null);
                      }
                      if (adviceMap != 3) {
                        Swal.fire({
                          title: "NO ADDRESS FOUND",
                          text: " Configure your address to use this feature",
                          icon: "info",
                          timer: 2000,
                          showConfirmButton: false,
                        });
                      }
                    }}
                    startIcon={<AddCircleIcon />}
                    sx={{
                      marginTop: { xs: 2, sm: 0 },
                      borderRadius: "8px",
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      color: selectedOptionSite !== 9 ? "white" : grey[800],
                      backgroundColor:
                        selectedOptionSite !== 9 ? blue[900] : grey[300],
                    }}
                  >
                    {selectedOptionSite !== 9
                      ? t("Add decoder pin")
                      : t("Cancel Add Pin")}
                  </Button>
                </Grid>
              )}
              {markers.length > 0 && (
                <Grid item>
                  <Button
                    value={10}
                    variant="contained"
                    size="large"
                    marginTop={{ xs: 2, sm: 0 }}
                    onClick={() => {
                      if (opt !== 2 && adviceMap == 3) {
                        setopt(2);
                      } else {
                        setopt(0);
                        setPoints([]);
                      }
                      if (adviceMap != 3) {
                        Swal.fire({
                          title: t("NO ADDRESS FOUND"),
                          text: t("Configure your address to use this feature"),
                          icon: "info",
                          timer: 2000,
                          showConfirmButton: false,
                        });
                      }
                    }}
                    startIcon={<FormatShapes />}
                    sx={{
                      marginTop: { xs: 2, sm: 0 },
                      marginLeft: { xs: 1, m: 3, md: 0 },
                      borderRadius: "8px",
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      color: opt === 2 ? grey[800] : "white",
                      backgroundColor: opt === 2 ? grey[300] : blue[900],
                    }}
                  >
                    {opt === 2
                      ? t("Cancel Add Boundaries")
                      : t("Add zone boundaries")}
                  </Button>
                </Grid>
              )}
              <Grid item>
                {points.length > 1 && opt === 2 && (
                  <Button
                    variant="contained"
                    size="large"
                    color="success"
                    onClick={() => showBoundaries(10)}
                    startIcon={<Save />}
                    style={{
                      marginLeft: 10,
                      color: "white",
                      borderRadius: "8px",
                      textTransform: "capitalize",
                      fontWeight: "bold",
                    }}
                  >
                   {t(`Save zone boundaries`)}
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={4}
              alignContent="center"
              justifyContent={{ xs: "start", md: "end" }}
            >
              <LoadingButton
                loading={isLoading}
                value={9}
                variant="outlined"
                onClick={saveMapView}
                startIcon={<Save />}
                size="large"
                color="success"
                sx={{ marginTop: { xs: 2, sm: 2 } }}
                style={{
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
              >
                {t(`Save`)}
              </LoadingButton>
            </Grid>
          </Grid>
        </>
      )}
      {/* BUTTONS OF SITE PLAN */}
      {selectedOption === 3 && (
        <>
          <Grid
            container
            xs={12}
            paddingY={2}
            justifyContent={{ xs: "center", md: "space-between" }}
            alignItems="center"
          >
            <Grid
              item
              container
              xs={12}
              md={8}
              spacing={2}
              justifyContent="start"
            >
              <Grid item>
                <Button
                  startIcon={<AddCircleIcon />}
                  variant="contained"
                  size="large"
                  onClick={() => {
                    if (selectedOptionSite !== 4) {
                      showBoundaries(4);
                    } else {
                      setSelectedOptionSite(null);
                    }
                  }}
                  sx={{
                    marginTop: { xs: 2, sm: 0 },
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    color: selectedOptionSite !== 4 ? "white" : grey[800],
                    backgroundColor:
                      selectedOptionSite !== 4 ? blue[900] : grey[300],
                  }}
                >
                  {selectedOptionSite !== 4
                    ? t("Add decoder pin")
                    : t("Cancel Add Pin")}
                </Button>
              </Grid>
              <Grid item>
                {imageMarkers.length > 0 && (
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<FormatShapes />}
                    sx={{
                      marginTop: { xs: 2, sm: 0 },
                      marginLeft: { xs: 1, m: 3, md: 0 },
                      borderRadius: "8px",
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      color: selectedOptionSite === 5 ? grey[800] : "white",
                      backgroundColor:
                        selectedOptionSite === 5 ? grey[300] : blue[900],
                    }}
                    onClick={() => {
                      if (selectedOptionSite !== 5) {
                        showBoundaries(5);
                      } else {
                        setSelectedOptionSite(null);
                        setaddZoneBoundarie(false);
                        setdrawing(false);
                      }
                    }}
                  >
                    {selectedOptionSite === 5
                      ? "Cancel Add Boundaries"
                      : t("Add zone boundaries")}
                  </Button>
                )}
              </Grid>
              {ControllerPinSitePlan.length < 1 && (
                <Grid item>
                  <Button
                    startIcon={<AddCircleIcon />}
                    variant="contained"
                    size="large"
                    onClick={() => {
                      setaddControllerPin(true);
                      handleImageClick();
                    }}
                    sx={{
                      marginTop: { xs: 2, sm: 0 },
                      borderRadius: "8px",
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      color: selectedOptionSite !== 4 ? "white" : grey[800],
                      backgroundColor:
                        selectedOptionSite !== 4 ? blue[900] : grey[300],
                    }}
                  >
                    {selectedOptionSite !== 4
                      ? t("Add Controller pin")
                      : t("Cancel Add Controller Pin")}
                  </Button>
                </Grid>
              )}
            </Grid>

            <Grid
              item
              container
              xs={12}
              md={4}
              alignContent="center"
              justifyContent={{ xs: "center", md: "end" }}
            >
              <LoadingButton
                onClick={() => save()}
                loadingPosition="start"
                loading={isLoading}
                variant="outlined"
                startIcon={<Save />}
                size="large"
                color="success"
                sx={{ marginTop: { xs: 2, sm: 2 } }}
                style={{
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                }}
              >
                {t(`Save`)}
              </LoadingButton>
            </Grid>
          </Grid>
        </>
      )}

      {/* ----------------------------------------------------- */}

      {/* 
      Selected Option 1  -> Map view
      Selected Option 2  -> Upload Img/ preview of Site Plan
      Selected Option 3  -> Edit view of Site Plan 
      */}
      <Grid
        item
        container
        xs={12}
        sx={{
          height: "60vh",
          minHeight: "600px",
          overflow: "auto",
          border: `3px solid ${grey[600]}`,
          zIndex: 0,
          objectFit: "contain",
        }}
        ref={containerRef}
        component={Paper}
        elevation={0}
        borderRadius={5}
      >
        {selectedOption === 1 && !loadingMap && mapCenter !== null ? (
          <MapContainer
            center={[mapCenterRef.current.lat, mapCenterRef.current.lng]}
            icon={{ customIcon }}
            zoom={23}
            onClick={onClick}
            scrollWheelZoom={true}
            style={{
              width: "100%",
              height: "auto",
            }}
          >
            <Marker
              position={[mapCenterRef.current.lat, mapCenterRef.current.lng]}
              icon={customIcon}
              title="My controller"
            />

            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/* 
              onClick={onClick}
              draggable={true}
              autoPan={true}
              position={[mapCenterRef.current.lat, mapCenterRef.current.lng]}
            /> */}
            {markers.map((marker, index) => (
              <Marker
                key={index}
                draggable={true}
                autoPan={true}
                eventHandlers={{
                  dragend: (e, ui) => markerDrag(marker.id, e, ui),
                }}
                position={marker.position}
              >
                <MarkerPopup zoneRef={marker.zone_ref} zoneMarker />
              </Marker>
            ))}

            {points.map((point, index) => (
              <Marker
                key={index}
                draggable={true}
                position={point}
                icon={polygonsPoint}
              >
                <Polygon draggable={true} positions={points} color="blue" />
              </Marker>
            ))}

            {shapes.length > 0
              ? shapes.map((point, index) => (
                  <Polygon
                    key={`shape-${point.zone_ref}-${index}`}
                    draggable={true}
                    positions={point.shape_points}
                    color={point.color}
                  >
                    <MarkerPopup zoneRef={point.zone_ref} zoneMarker={false} />
                  </Polygon>
                ))
              : null}
            {zoneMapMarker && <MapEventsHandler />}
            {opt === 2 && <MapEventsHandler />}
          </MapContainer>
        ) : (
          ""
        )}
        {selectedOption === 1 && loadingMap && (
          <Grid container justifyContent="center">
            <SpinnerCircular />
          </Grid>
        )}

        {selectedOption === 3 && img !== "" && (
          <>
            {/* {imageMarkers
              ? imageMarkers.map((marker) => (
                  <>


                  
                    <Draggable
                      key={marker.id}
                      defaultPosition={{
                        x: marker.position.x,
                        y: marker.position.y,
                      }}
                      position={{ x: marker.position.x, y: marker.position.y }}
                      onDrag={(e, ui) => handleMarkerDrag(marker.id, e, ui)}
                    >
                      <PlaceIcon
                        style={{
                          width: "5vw",
                          height: "5vh",
                          zIndex: 1,
                          color:
                            selectedMarkerId === marker.id ? "red" : "#032C65",
                        }}
                        key={marker.id}
                        onClick={(event) => {
                          setSelectedMarkerId(marker.id);
                          setAnchorEl(event.currentTarget);
                          setopenZoneInfo(true);
                        }}
                      />
                    </Draggable>
                    {selectedMarkerId === marker.id && (
                      <MarkerPopup zoneRef={marker.zone_ref} zoneMarker />
                    )}

                    
                  </>
                ))
              : ""} */}
            {markerinfo !== null && (
              <MarkerPopup zoneRef={markerinfo.zone_ref} zoneMarker={true} />
            )}

            {dataShape !== null && (
              <MarkerPopup zoneRef={dataShape.zone_ref} zoneMarker={false} />
            )}

            <PolygonMaker
              poly_completed={poly_completed}
              drawing={drawing}
              dialogAction={dialogAction}
              imageMarkers={imageMarkers}
              controllerPinSitePlan={ControllerPinSitePlan}
              clickMarkers={handleImageClick}
              containerWidth={containerWidth}
              update_poly_position={(poly, n) => update_poly_position(poly, n)}
              polygons={polygons}
              containerHeight={containerHeight}
              childR={getpolyref}
              data={dataShape}
              img={img}
              selectedMarker={(e, marker) => showInfo_siteplan(e, marker)}
              removed={removedBoundarie}
            />
          </>
        )}
        {selectedOption === 3 && img === "" && (
          <Button
            component="label"
            startIcon={<CloudUploadIcon style={{ fontSize: 24 }} />}
            sx={{
              borderRadius: "8px",
              textTransform: "capitalize",
              fontWeight: "bold",
              color: "grey",
              backgroundColor: "white",
              width: "100%",
              fontSize: "24px",
              height: "100%",
            }}
          >
            {t(`Upload image`)}
            <VisuallyHiddenInput type="file" onChange={handleIMG} />
          </Button>
        )}
      </Grid>

      {selectedOption === 3 && (
        <Grid item>
          <Button
            component="label"
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            sx={{
              marginTop: { xs: 2, sm: 4 },
              marginRight: { xs: 2, sm: 14 },
              borderRadius: "8px",
              textTransform: "capitalize",
              fontWeight: "bold",
              color: "white",
              backgroundColor: blue[900],
            }}
          >
            {t(`Upload image`)}
            <VisuallyHiddenInput type="file" onChange={handleIMG} />
          </Button>
        </Grid>
      )}

      {/* <Grid container justifyContent="end" marginTop={3}>
        <Button
          size="large"
          variant="outlined"
          onClick={cleanMapView}
          startIcon={<EditOff />}
          color="error"
          style={{
            color: red[900],
            borderRadius: "8px",
            backgroundColor: red[50],
            textTransform: "capitalize",
            marginBottom: 20,
          }}
        >
          Restart Map
        </Button>
      </Grid> */}

      {/* ----------------------------------------------------- */}

      {/* ----------------------------------------------------- */}

      {/* BUTTONS OF SITE PLAN */}
      {/* {selectedOption === 2 && (
        <Button
          component="label"
          style={{
            width: "10%",
            color: "black",
            borderRadius: "8px",
            top: "20%",

            position: "absolute",
          }}
          startIcon={<AddCircleIcon />}
        >
          UPLOAD IMG
          <VisuallyHiddenInput type="file" onChange={handleIMG} />
        </Button>
      )} */}

      {/* Window / Modal of decoder pin & add zone Boundaries */}
      <Modal
        open={open}
        onClose={closebox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ paddingTop: 10 }}
      >
        <Grid
          item
          container
          xs={11}
          sm={10}
          md={9}
          lg={8}
          component={Paper}
          elevation={4}
          borderRadius={6}
          justifyContent="center"
          sx={{ margin: "0 auto" }}
        >
          <Grid container justifyContent="end" padding={2}>
            <CloseIcon
              size="large"
              color="primary"
              sx={{
                right: "18%",
                top: "8%",
                cursor: "pointer",
              }}
              onClick={() => {
                closebox();
                setopt(0);
                setPoints([]);
              }}
            />
          </Grid>
          {/* --selectedOptionSite===4 ---------> DECODER PIN MODAL SITE PLAN <---------------- */}

          {selectedOptionSite === 4 && (
            <>
              <Grid
                item
                container
                xs={11}
                md={9}
                justifyContent="center"
                paddingY={5}
              >
                <Grid container justifyContent="center" marginBottom={3}>
                  <Typography
                    style={{ fontWeight: "bold", color: "#032C65" }}
                    textAlign="center"
                    variant="h5"
                  >
                   {t(`Add decoder pin`)}
                  </Typography>
                </Grid>
                <Grid container justifyContent="center">
                  <Typography style={{ fontWeight: "bold" }}>
                   {t(`Select a zone and place a pin in the map.`)}
                  </Typography>
                </Grid>
                <Grid container xs={10} spacing={1} marginTop={3}>
                  {zonesF
                    ? zonesF.map((item, index) => {
                        if (item) {
                          const isChecked = imageMarkers.some(
                            (img) => img.zone_ref === item.decoder
                          );

                          return (
                            <Grid
                              item
                              xs={2.7}
                              margin={0.5}
                              component={Paper}
                              elevation={3}
                              borderRadius={3}
                              key={index}
                              onClick={() => {
                                if (!isChecked) {
                                  markerSite(item, selectedOptionSite);
                                } else {
                                  alert(t(`This zone is already in use`));
                                }
                              }}
                              className={`  card-zone-maps ${
                                isChecked ? "checked" : ""
                              }`}
                              justifyContent="center"
                              alignContent="center"
                              textAlign="center"
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {item.zone_name} {t(`Zone`)} {index + 1}
                              </span>
                            </Grid>
                          );
                        }
                      })
                    : ""}
                </Grid>
              </Grid>
            </>
          )}

          {/* --selectedOptionSite===9 ---------> DECODER PIN MODAL MAP VIEW <---------------- */}

          {selectedOptionSite === 9 && (
            <Grid
              item
              container
              xs={11}
              md={9}
              justifyContent="center"
              paddingY={5}
            >
              <Grid container justifyContent="center" marginBottom={3}>
                <Typography
                  style={{ fontWeight: "bold", color: "#032C65" }}
                  textAlign="center"
                  variant="h5"
                >
                 {t(`Add decoder pin`)}
                </Typography>
              </Grid>
              <Grid container justifyContent="center">
                <Typography style={{ fontWeight: "bold" }}>
                  {t(`Select a zone and place a pin in the map.`)}
                </Typography>
              </Grid>
              <Grid container xs={10} spacing={1} marginTop={3}>
                {zonesF
                  ? zonesF.map((item, index) => {
                      if (item) {
                        const isCheckedMap = markers.some(
                          (itemMap) => itemMap.zone_ref === item.decoder
                        );

                        return (
                          <Grid
                            item
                            xs={2.7}
                            margin={0.5}
                            component={Paper}
                            elevation={3}
                            borderRadius={3}
                            sx={{
                              padding: 1,
                              height: "60px",
                              color: isCheckedMap ? green[700] : blueGrey[800],
                            }}
                            onClick={() => {
                              if (!isCheckedMap) {
                                markerSite(item, selectedOptionSite);
                              } else {
                                alert(t(`This zone is already in use`));
                              }
                            }}
                            justifyContent="center"
                            alignContent="center"
                            textAlign="center"
                          >
                            <span
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              {item.zone_name
                                ? item.zone_name
                                : t(`Zone`)+ ` ${index + 1}`}
                            </span>
                          </Grid>
                        );
                      }
                    })
                  : ""}
              </Grid>
            </Grid>
          )}

          {/* ---------------------------------------------------------------------------------- */}

          {/* --selectedOptionSite===5---------> ADD ZONE BOUNDARIES SITEPLAN <---------------- */}

          {selectedOptionSite === 5 && color_circles(opt)}

          {/* --selectedOptionSite===10---------> ADD ZONE BOUNDARIES MAP VIEW <---------------- */}

          {selectedOptionSite === 10 && color_circles(opt)}

          {/* ---------------------------------------------------------------------------------- */}
        </Grid>
      </Modal>
      <Modal
        open={manualRunOpen}
        onClose={() => {
          setManualRunOpen(false);
          setSelManualZone(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ paddingTop: 10 }}
      >
        <Grid container xs={12} justifyContent="center">
          <Box sx={styleRadioButton}>
            <CloseIcon
              size="large"
              color="primary"
              sx={{ position: "absolute", right: 10 }}
              onClick={() => {
                setManualRunOpen(false);
                setSelManualZone(null);
              }}
            />
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              marginBottom={4}
            >
              {t(`Manual Run Duration:`)}{" "}
              {selManualZone
                ? selManualZone.zone_name
                  ? selManualZone.zone_name
                  : t(`Zone`) +`  ${selManualZone.number}`
                : ""}
            </Typography>
            <label
              style={{
                color: "#032C65",
                fontWeight: "normal",
                fontSize: "16px",
              }}
            >
              {t(`Hours`)}:{" "}
            </label>
            <select
              id="hour"
              name="hour"
              value={runtimeHours}
              style={{
                width: "20%",
                height: "40px",
                textAlign: "center",
                borderRadius: "5px",
                marginRight: "10px",
              }}
              onChange={handleRuntimeHours}
            >
              {Array.from({ length: 24 }, (_, i) => i).map((hours) => (
                <option key={hours} value={hours}>
                  {hours}
                </option>
              ))}
            </select>
            <label
              style={{
                color: "#032C65",
                fontWeight: "normal",
                fontSize: "16px",
              }}
            >
              {t(`Minutes`)}:{" "}
            </label>{" "}
            <select
              id="minutes"
              name="minutes"
              value={runtimeMins}
              style={{
                width: "20%",
                height: "40px",
                textAlign: "center",
                borderRadius: "5px",
              }}
              onChange={handleRuntimeMinutes}
            >
              {Array.from({ length: 60 }, (_, i) => i).map((minutes) => (
                <option key={minutes} value={minutes}>
                  {minutes}
                </option>
              ))}
            </select>
            <LoadingButton
              variant="contained"
              color="primary"
              startIcon={<PlayArrow />}
              sx={{
                width: "50%",
                height: "10%",
                fontSize: "14px",
                marginTop: "30px",
                borderRadius: "8px",
                fontWeight: "normal",
                textTransform: "capitalize",
                marginBottom: 4,
              }}
              onClick={() =>
                selManualZone ? startZone(selManualZone.number) : null
              }
              loading={pushingManual}
            >
              {t(`Run`)}
            </LoadingButton>
          </Box>
        </Grid>
      </Modal>
    </>
  );
};

export const Map_zoneUpload = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState(1);
  const [imgUrl, setImgUrl] = useState("");

  const buttonClick = (optionNumber) => {
    // console.log("set to option:", optionNumber);
    setSelectedOption(optionNumber);
  };

  return (
    <Grid container justifyContent="center">
      <Grid
        sx={{
          borderRadius: "8px",
          border: `1px solid ${grey[300]}`,
          backgroundColor: "#F7F8FA",
          padding: 2,
        }}
        item
        container
        component={Paper}
        elevation={0}
        xs={11}
        sm={10}
        justifyContent="space-between"
      >
        <Grid
          item
          container
          xs={12}
          sm={5}
          md={4}
          justifyContent="space-between"
        >
          <Grid
            item
            xs={2}
            sm={2}
            alignItems="center"
            component={Stack}
            justifyContent="center"
          >
            <IconButton
              size="large"
              sx={{ backgroundColor: "white" }}
              onClick={() => {
                const newState = location.state;
                newState.program = null;

                navigate(`/dashboard/${location.state.id}`, {
                  state: newState,
                });
              }}
            >
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            alignItems="center"
            component={Stack}
            justifyContent="start"
            paddingLeft={5}
          >
            <Typography variant="h6" fontWeight="bolder" color="#6B7A99">
              {t(`Maps`)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={7}
          justifyContent={{ xs: "end", md: "start" }}
        >
          <Grid item>
            <Button
              variant="text"
              size="large"
              onClick={() => buttonClick(1)}
              sx={{
                backgroundColor: "white",
                fontWeight: "bold",
                textTransform: "capitalize",
                color: selectedOption === 1 ? green[500] : blueGrey[600],
                boxShadow: selectedOption === 1 ? 4 : 0,
                zIndex: selectedOption === 1 ? 1 : 0,
              }}
            >
              {t(`Map`)}
            </Button>
            <Button
              variant="text"
              size="large"
              onClick={() => {
                buttonClick(3);
                // Swal.fire({
                //   title: "Upcoming Feature",
                //   text: "Site Plan will enable you to showcase you zone's location by uploading your own picture, our teams of engineers are working on delivering this feature.",
                //   icon: "info",
                //   confirmButtonText: "Continue, back to maps",
                //   confirmButtonColor: "#032C65",
                //   denyButtonText: "Cancel",
                // }).then((data) => {});
              }}
              sx={{
                backgroundColor: "white",
                fontWeight: "bold",
                textTransform: "capitalize",
                color: selectedOption === 3 ? green[500] : blueGrey[600],
                boxShadow: selectedOption === 3 ? 4 : 0,
                zIndex: selectedOption === 3 ? 1 : 0,
              }}
            >
              {t(`Site Plan`)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={11} sm={10} justifyContent="center">
        <MapsWrapper selectedOption={selectedOption} onImgChange={setImgUrl} />
      </Grid>
      {imgUrl !== "" && selectedOption === 2 && (
        <Button
          onClick={() => buttonClick(3)}
          style={{
            width: "10%",
            backgroundColor: "#032C65",
            color: "white",
            borderRadius: "8px",
            top: " 25%",
            left: "45%",
            position: "absolute",
          }}
        >
          {t(`Modify`)}
        </Button>
      )}
    </Grid>
  );
};

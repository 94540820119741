import axios from "axios";
import { useUser } from "../../Auth/auth";
import { useEffect, useState } from "react";

const API_ID = process.env.REACT_APP_API_ID;
const API_REGION = process.env.REACT_APP_AWS_COGNITO_REGION;
const API_STAGE = process.env.REACT_APP_API_STAGE;

export const apiUser = axios.create({
  baseURL: `https://${API_ID}.execute-api.${API_REGION}.amazonaws.com/${API_STAGE}`,
});

export const AxiosInterceptor = ({ children }) => {
  const { getAuthTokens } = useUser();
  useEffect(() => {
    apiUser.interceptors.request.use((config) => {
      return getAuthTokens()
        .then((data) => {
          config.headers.Authorization = `Bearer ${data.idToken}`;

          return Promise.resolve(config);
        })
        .catch((e) => {
          console.error(e);
          return Promise.resolve(config);
        });
    });
  }, []);
  return children;
};

const { get, post, put, delete: destroy } = apiUser;

export { get, post, put, destroy };

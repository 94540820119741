import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Grid,
  Paper,
  Stack,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { useUser } from "../../Auth/auth";
import { useFormik } from "formik";
import { SpinnerCircular } from "spinners-react";
import "./MainAcc.css";
import { apiUser } from "../../Apis/configs/axiosConfig";
import { MySwal } from "../../Components/helpers/SwalAlert";
import { useLocation, useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";
import { People } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export const MainAccount = () => {
  const navigate = useNavigate();
  const { userAttributes, logOut } = useUser();
  const [userData, setuserData] = useState(userAttributes ?? {});
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setuserData(userAttributes ?? {});
  }, [userAttributes]);

  const formik = useFormik({
    initialValues: {
      first_name: userAttributes ? userAttributes.firstName : "",
      last_name: userAttributes ? userAttributes.lastName : "",
      company_name: userAttributes ? userAttributes.companyName : "",
      email: userAttributes ? userAttributes.email : "",
      password: userAttributes ? userAttributes.password : "",
      repeat_password: userAttributes ? userAttributes.repeat_password : "",
      username: userAttributes ? userAttributes.username : "",
      id: userAttributes ? userAttributes.id : "",
    },
    onSubmit: async (values) => {
      // console.log(values);
      try {
        const request = await apiUser.put("/account", values);
        // console.log(request);

        if (request.data.success === true) {
          MySwal.fire({
            title: t("Changes saved"),
            text: t("Your account has been modified successfully"),
            icon: "success",
            timer: 2000,
          });
        }
        if (request.data.message === "EmailAlreadyRegistered") {
          MySwal.fire({
            title: "Error",
            text: t("Email already registered"),
            icon: "warning",
            timer: 2000,
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
  });
  // console.log(userData);
  const deleteAccount = async () => {
    try {
      const result = await MySwal.fire({
        title: t("Delete master account"),
        text: t(`Are you sure to delete`) + ` ${userData.username} ?`,
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#032C65",
        confirmButtonColor: "#d33",
        confirmButtonText: t("Yes, delete it!"),
        cancelButtonText: t("Cancel")
      });

      if (result.isConfirmed) {
        setIsLoading(true);
        const resp = await apiUser.delete(`/account`, userData.id);
        if (resp.data.success) {
          MySwal.fire({
            title: t("Account deleted"),
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
          }).then(() => {
            logOut();
            navigate("/login");
          });
        } else if (resp.data.message === "AccountHasControllers") {
          MySwal.fire({
            title: "Error",
            text: t("Failed to delete the account. Account has controllers registered."),
            icon: "error",
          });
        }
      }
    } catch (e) {
      console.error(e);
      MySwal.fire({
        title: "Error",
        text: t("Failed to delete the account."),
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container xs={12} justifyContent="center">
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid
        sx={{
          borderRadius: "8px",
          border: `1px solid ${grey[300]}`,
          backgroundColor: "#F7F8FA",
          padding: 2,
        }}
        item
        container
        component={Paper}
        elevation={0}
        justifyContent={{ xs: "center", sm: "center", md: "space-between" }}
        xs={10}
      >
        <Grid
          sx={{ borderRadius: "8px" }}
          item
          xs={12}
          sm={6}
          md={7}
          lg={8}
          alignItems="center"
          justifyContent="end"
          component={Stack}
        >
          <Typography variant="h5" fontWeight="bolder" color="#6B7A99">
            {t(`Main Account Management`)}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={4}
          md={4}
          lg={3}
          paddingX={3}
          paddingY={0.2}
          textAlign="left"
          direction="row"
          justifyContent="end"
          alignItems="center"
          style={{ borderRadius: "13px" }}
          sx={{
            marginTop: { xs: 1, sm: 1, md: 0, lg: 0 },
          }}
        >
          <Button
            onClick={() =>
              navigate("/subAcc", {
                state: {
                  id: location.state ? location.state.id : null,
                },
              })
            }
            variant="contained"
            style={{
              backgroundColor: "#032C65",
              color: "white",
              borderRadius: "10px",
              fontSize: 16,
              paddingY: 3,
              paddingX: 6,
            }}
            startIcon={<People />}
          >
            {t(`Sub-Accounts`)}
          </Button>
        </Grid>
      </Grid>
      <form
        onSubmit={formik.handleSubmit}
        style={{
          width: "100%",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          container
          xs={11}
          sm={10}
          md={7}
          lg={4}
          component={Paper}
          elevation={4}
          justifyContent="center"
          marginY={4}
          paddingY={4}
          borderRadius={6}
        >
          {userData ? (
            <>
              <Grid item container xs={12} justifyContent="center">
                <ManageAccountsIcon sx={{ fontSize: 40, color: "#032C65" }} />
                <Typography
                  style={{
                    textAlign: "center",
                    color: "#032C65",
                    fontWeight: "normal",
                    marginTop: "5px",
                    marginBottom: "10px",
                  }}
                  id="modal-modal-title"
                  variant="h6"
                >
                  {t(`Edit profile`)}
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={10}
                justifyContent="center"
                marginTop={1}
              >
                <label>{t(`First name`)}</label>
                <input
                  type="text"
                  placeholder={userData.firstName}
                  id="first_name"
                  name="first_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    textAlign: "center",
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={10}
                justifyContent="center"
                marginTop={1}
              >
                <label>{t(`Last name`)}</label>
                <input
                  type="text"
                  placeholder={userData.lastName}
                  id="last_name"
                  name="last_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    textAlign: "center",
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={10}
                justifyContent="center"
                marginTop={1}
              >
                <label>{t(`Email`)}</label>
                <input
                  type="email"
                  placeholder={userData.email}
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    textAlign: "center",
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={10}
                justifyContent="center"
                marginTop={1}
              >
                <label>{t(`Company name`)}</label>
                <input
                  type="text"
                  placeholder={userData.companyName}
                  id="company_name"
                  name="company_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    textAlign: "center",
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={10}
                justifyContent="center"
                marginTop={1}
              >
                <label>{t(`Username`)}</label>
                <input
                  type="text"
                  placeholder={userData.username}
                  id="username"
                  name="username"
                  readOnly
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    textAlign: "center",
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={10}
                justifyContent="center"
                marginTop={1}
              >
                <label>{t(`Password`)}</label>
                <input
                  type="password"
                  placeholder="*************************"
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    textAlign: "center",
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={10}
                justifyContent="center"
                marginTop={1}
              >
                <label>{t(`Confirm Password`)}</label>
                <input
                  type="password"
                  placeholder="*************************"
                  id="repeat_password"
                  name="repeat_password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.repeat_password}
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    textAlign: "center",
                  }}
                />
              </Grid>
              <Grid item container xs={12} justifyContent="center">
                <LoadingButton
                  type="submit"
                  loading={formik.isSubmitting}
                  sx={{
                    marginTop: "20px",
                    marginBottom: "10px",
                    height: 40,
                    backgroundColor: formik.isSubmitting ? "white" : "#032C65",
                    color: formik.isSubmitting ? "#032C65" : "white",
                    fontWeight: "normal",
                    borderRadius: "8px",
                    border: "1px solid grey",
                    boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                    paddingY: 3,
                    paddingX: 4,
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#032C65",
                    },
                  }}
                >
                  {t(`Save Changes`)}
                </LoadingButton>
              </Grid>
            </>
          ) : (
            <SpinnerCircular />
          )}
        </Grid>
      </form>
      {/* <SubAccounts /> */}
      <Grid item container xs={6} sm={5} md={5} justifyContent="center">
        <Button
          onClick={deleteAccount}
          className="button-new-Zone-style"
          variant="container"
          style={{
            backgroundColor: "#83072D",
            fontWeight: "normal",

            color: "white",
            border: "1px solid grey ",
            height: "6vh",
            borderRadius: "15px",
            padding: 18,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
          }}
          startIcon={<PersonRemoveIcon />}
        >
          {t(`Delete Account`)}
        </Button>
      </Grid>
    </Grid>
  );
};

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "../views/login/Login";
import { Signup as NewSignUp } from "../views/signup/SignUp";
import { MyControllers_view } from "../views/MyControllers/MyControllers_view";
import { ProgramsView } from "../views/Programs/ProgramsView.jsx";
import { ZonesView } from "../views/Zones/Zones_View";
import { Dashboard } from "../views/Dashboard/Dashboard";
import { Zone_grouping } from "../views/Zone_grouping/ZoneGrouping.jsx";
import { ZoneMaps } from "../views/map_zone/Map_zone.jsx";
import { MainAccount } from "../views/mainAccount/MainAccount.jsx";
import { SubAccounts } from "../views/subAccounts/SubAccounts.jsx";
import { useUser } from "../Auth/auth.js";

export const AppRouter = () => {
  const { isLoggedIn } = useUser();

  return (
    <Routes>
      {isLoggedIn ? (
        <>
          <Route path="mycontrollers" element={<MyControllers_view />} />
          <Route path="programs/:id" element={<ProgramsView />} />
          <Route path="zone/:id" element={<ZonesView />} />
          <Route path="zone_grouping/:id" element={<Zone_grouping />} />
          <Route path="dashboard/:id" element={<Dashboard />} />
          <Route path="map_zone/:id" element={<ZoneMaps />} />
          <Route path="mainAcc/" element={<MainAccount />} />
          <Route path="subAcc/" element={<SubAccounts />} />
    
          <Route path="*" element={<Navigate to="/mycontrollers" replace />} />
        </>
      ) : (
        <>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<NewSignUp />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </>
      )}
    </Routes>
  );
};

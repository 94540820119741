import React from "react";
import App from "./App";

import ReactDOM from "react-dom/client";
import { UserProvider } from "./Auth/auth";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import './i18n';
// import { AxiosInterceptor } from "./Apis/configs/axiosConfig";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserProvider>
      {/* <AxiosInterceptor> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {/* </AxiosInterceptor> */}
    </UserProvider>
  </React.StrictMode>
);

reportWebVitals();

import React, { createRef, useState } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import leftSectionImage from "./images/Krainlogo-LeftSection.png";
import sitemasterTopLogo from "./images/SitemasterLogo.png";
import sitemasterlogo from "./images/SitemasterLogo.png";
import { useFormik } from "formik";
import { useUser } from "../../Auth/auth";
import {
  Backdrop,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { MySwal } from "../../Components/helpers/SwalAlert";
import Modal from "@mui/material/Modal";
import { Typography, Box } from "@mui/material";
import { Close, EmailOutlined, Lock } from "@mui/icons-material";
import { blueGrey, green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

const modalStyleEdit = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  bgcolor: "#f7f8fa",
  border: "1px solid grey",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
  padding: "20px",
  textAlign: "center",
  objectFit: "contain",
  overflow: "auto",
  zIndex: 0,
};

const CodeInputs = ({ inputValues, handleInputChange }) => {
  const nInputs = 6;
  const [inputRefs] = useState(() =>
    Array.from({ length: nInputs }, () => createRef())
  );

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleKeyPress = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex < nInputs - 1 ? prevIndex + 1 : 0;
      const nextInput = inputRefs[nextIndex].current;
      nextInput.focus();
      nextInput.select();

      return nextIndex;
    });
  };

  return (
    <>
      {inputRefs.map((ref, index) => (
        <input
          ref={ref}
          key={`code-confirmation-input-${index}`}
          value={inputValues[index] || ""}
          style={{
            marginBottom: "10px",
            padding: "17px",
            width: "2%",
            textAlign: "center",
            borderRadius: "8px",
            marginRight: "3px",
            marginTop: "15px",
            objectFit: "contain",
          }}
          maxLength="1"
          onChange={(e) => {
            handleInputChange(index, e.target.value);
          }}
          onKeyUp={handleKeyPress}
          onClick={(e) => {
            setCurrentIndex(index);
            e.target.select();
          }}
        />
      ))}
    </>
  );
};

export const Login = () => {
  const { logIn, resetPassword, resetPasswordConfirm } = useUser();
  const isSmallScreen = useMediaQuery("(max-width:1024px)");
  const [inputValues, setInputValues] = useState(Array(6).fill(""));
  const [open, setOpen] = useState(false);
  const [running, setRunning] = useState(false);
  const [step, setStep] = useState(0);
  const [newPass, setnewPass] = useState("");
  const [user, setuser] = useState("");
  const [confirmpass, setConfirmPass] = useState("");
  const { t, i18n } = useTranslation();

  const modalStyleAdaptable = {
    ...modalStyleEdit,
    width: isSmallScreen ? "80vw" : "50vw",
    height: isSmallScreen ? "40vh" : "35vh",
    minHeight: "400px",
    overflow: "auto",
  };

  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      setRunning(true);

      logIn(values.email, values.password)
        .then((data) => {
          MySwal.fire({
            title: t("Login Successful"),
            text: t(`Welcome back`) +' !',
            icon: "success",
            timer: 1500,
            confirmButtonColor: "#032C65",
            confirmButtonText: t("Confirm"),
            background: "#f7f8fa",
          });
          navigate("/mycontrollers");
        })
        .catch((e) => {
          console.error(e);

          if (e.name === "UserNotFoundException") {
            MySwal.fire({
              title: t("Login unsuccessful"),
              text: t(`User does not exist`),
              icon: "error",
              confirmButtonColor: "#032C65",
              confirmButtonText: t("Confirm"),
              background: "#f7f8fa",
            });
          } else if (e.name === "NotAuthorizedException") {
            MySwal.fire({
              title: t("Login unsuccessful"),
              text: t(`Incorrect username or password.`),
              icon: "error",
              confirmButtonColor: "#032C65",
              confirmButtonText: t("Confirm"),
              background: "#f7f8fa",
            });
          } else if (e.name === "UserAlreadyAuthenticatedException") {
            MySwal.fire({
              title: t("Login unsuccessful"),
              text: t(`There is already a signed in user.`),
              icon: "error",
              confirmButtonColor: "#032C65",
              confirmButtonText:t("Confirm"),
              background: "#f7f8fa",
            });
          } else if (e.name === "EmptySignInPassword") {
            MySwal.fire({
              title: t("Login unsuccessful"),
              text: t(`Password is required to signIn`),
              icon: "error",
              confirmButtonColor: "#032C65",
              confirmButtonText: t("Confirm"),
              background: "#f7f8fa",
            });
          } else if (e.name === "EmptySignInUsername") {
            MySwal.fire({
              title: t("Login unsuccessful"),
              text: t(`Username is required to signIn`),
              icon: "error",
              confirmButtonColor: "#032C65",
              confirmButtonText: t("Confirm"),
              background: "#f7f8fa",
            });
          } else {
            MySwal.fire({
              title: t("Login unsuccessful"),
              text: t(`Something went wrong.`),
              icon: "error",
              confirmButtonColor: "#032C65",
              confirmButtonText: "Confirm",
              background: "#f7f8fa",
            });
          }
          setRunning(false);
        });
    },
  });

  const requestPass = async () => {
    let username = user;
    setRunning(true);
    try {
      const request = await resetPassword(username);

      // console.log(request);
      if (request) {
        setStep(2);
      }
    } catch (e) {
      closeBox();
      if (e.name === "LimitExceededException") {
        MySwal.fire({
          title: " Error ",
          text: t("Attempt limit exceeded, please try later."),
          icon: "warning",
          confirmButtonColor: "#032C65",
          confirmButtonText: t("Confirm"),
        });
      }
    }

    setRunning(false);
  };

  const confirmCode = () => setStep(3);

  const changePass = async () => {
    const code_to_string = inputValues.join("");

    if (newPass === confirmpass) {
      if (newPass.length < 6) {
        MySwal.fire({
          title: t("Password too short!"),
          text: t("Password does not meet minimum requirement of 6 characters."),
          icon: "info",
          confirmButtonColor: "#032C65",
          confirmButtonText: t("Got it"),
          background: "#f7f8fa",
          zIndex: 10,
        });
        return;
      }

      setRunning(true);

      try {
        const request = await resetPasswordConfirm(
          user,
          newPass,
          code_to_string
        );
        if (request) {
          closeBox();
          MySwal.fire({
            title: t("Password changed"),
            text: t(`Your password has been changed`),
            icon: "success",
            timer: 1500,
            confirmButtonColor: "#032C65",
            confirmButtonText: t("Confirm"),
            background: "#f7f8fa",
            zIndex: 10,
          });
        }
      } catch (e) {
        closeBox();

        setRunning(false);
        console.error(e);

        if (e.name === "ExpiredCodeException") {
          MySwal.fire({
            title: t("Error "),
            text: t(`Invalid code provided, please request a code again.`),
            icon: "warning",
            confirmButtonColor: "#032C65",
            confirmButtonText: "Confirm",
            background: "#f7f8fa",
          });

          setStep(0);
          setOpen(true);
        } else {
          closeBox();
          setStep(0);
          MySwal.fire({
            title: "Error ",
            text: t(`An error has occured`),
            icon: "error",

            confirmButtonColor: "#032C65",
            confirmButtonText: t("Confirm"),
            background: "#f7f8fa",
          });
        }
      } finally {
        setRunning(false);
      }
    } else {
      closeBox();

      setRunning(false);

      MySwal.fire({
        title: "Error",
        text: t(`Passwords do not match`),
        icon: "error",
        confirmButtonColor: "#032C65",
        confirmButtonText:t("Confirm"),
        background: "#f7f8fa",
        zIndex: 10,
      });
      setStep(3);
      setOpen(true);
    }
  };

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  };

  const closeBox = () => {
    setOpen(false);
    setStep(0);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100vh",
          width: { xs: "100%", sm: "90%", md: "80%", lg: "70%" },
          margin: "auto",
        }}
      >
        {running && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={running}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          item
          container
          xs={11}
          sm={12}
          md={12}
          lg={9}
          justifyContent="center"
          component={Paper}
          elevation={7}
        >
          <Grid
            item
            xs={0}
            sm={6}
            sx={{
              display: { xs: "none", sm: "inherit" },
              backgroundColor: "#042C66",
              color: "white",
            }}
          >
            <img
              alt="Section"
              src={leftSectionImage}
              style={{ objectFit: "contain", width: "100%", height: "auto" }}
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            alignContent="center"
            xs={11}
            sm={6}
            sx={{
              backgroundColor: "white",
              color: "#18355E",
            }}
          >
            <Grid item xs={10} marginBottom={5}>
              <Grid
                item
                xs={12}
                display={{
                  xs: "block",
                  sm: "none",
                  md: "none",
                  lg: "none",
                  xl: "none",
                }}
              >
                <img
                  alt="Section"
                  src={sitemasterTopLogo}
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "auto",
                    marginBottom: "15%",
                  }}
                />
              </Grid>
              <Typography
                variant="h4"
                fontWeight="bold"
                textAlign="start"
                marginLeft="10%"
              >
                {t(`Sign In`)}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                id="email-input"
                label={t("Email")}
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                variant="outlined"
                placeholder="johndoe@gmail.com"
                sx={{ width: "80%", marginBottom: 3 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={10} marginBottom={5}>
              <TextField
                id="password-input"
                label={t("Password")}
                name="password"
                variant="outlined"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                sx={{ width: "80%" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item container xs={10} justifyContent="center">
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={() => formik.handleSubmit()}
                  sx={{
                    width: "80%",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    backgroundColor: green[600],
                    paddingY: 1,
                  }}
                  type="submit"
                >
                  {t(`Sign In`)}
                </Button>
              </Grid>
              <Grid
                item
                container
                xs={12}
                marginTop={1}
                color="#18355E"
                justifyContent="space-evenly"
              >
                <Link
                  onClick={setOpen}
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    marginRight: 10,
                  }}
                >
                  {t(`Forgot password`)}?
                </Link>
                <Link
                  to="/signup"
                  style={{ textDecoration: "none", fontWeight: "bold" }}
                >
                  {t(`Sign Up`)}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal open={open} onClose={closeBox} sx={{ zIndex: 9 }}>
        <Box sx={modalStyleAdaptable}>
          <Grid container justifyContent="end" padding={1}>
            <Close
              size="large"
              color="primary"
              sx={{
                right: 6,
                top: "1%",
                cursor: "pointer",
              }}
              onClick={closeBox}
            />
          </Grid>
          <img
            className="logo-section"
            alt="Section"
            style={{ width: "100%", maxWidth: "250px", marginBottom: "20px" }}
            src={sitemasterlogo}
          />

          {step === 0 && (
            <>
              <Typography
                style={{
                  textAlign: "center",
                  color: "#032C65",
                  fontSize: "22px",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                {t(`Please provide your Username / Email`)}
              </Typography>
              <input
                type="text"
                placeholder={t("Username / Email")}
                onChange={(e) => setuser(e.target.value)}
                style={{
                  border: "1px solid grey",
                  textAlign: "center",
                  borderRadius: " 4px ",
                  width: "70%",
                  height: "10%",
                  fontSize: 18,
                  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                }}
              />
              <LoadingButton
                type="submit"
                loading={running}
                onClick={requestPass}
                style={{
                  marginTop: "20px",
                  top: "5%",
                  width: "50%",
                  backgroundColor: "#032C65",
                  color: "white",
                  fontWeight: "normal",
                  border: "1px solid grey",
                  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                }}
              >
                {t(`Request`)}
              </LoadingButton>
              {user && (
                <Link
                  onClick={() => {
                    setStep(2);
                    setInputValues(["", "", "", "", "", ""]);
                  }}
                  style={{
                    top: "75%",
                    width: "50%",
                    right: "25%",
                    marginTop: 15,
                    position: "absolute",
                  }}
                  className={"log-in-text-wrapper-4"}
                >
                  {t(`Already have a code ?`)}
                </Link>
              )}
            </>
          )}

          {step === 2 && (
            <>
              <Typography
                style={{
                  textAlign: "center",
                  color: "#032C65",
                  fontSize: "24px",
                  marginTop: "25px",
                }}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
               {t(`A confirmation code has been sent to your email`)}
              </Typography>

              <Typography
                style={{
                  textAlign: "center",
                  color: "black",
                  fontSize: "22px",
                  fontWeight: "normal",
                  marginTop: "25px",
                }}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                {t(`Submit your confirmation code`)}
              </Typography>
              <CodeInputs
                handleInputChange={handleInputChange}
                inputValues={inputValues}
              />
              <Grid container justifyContent="center">
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={running}
                  onClick={confirmCode}
                  style={{
                    display: "block",
                    marginTop: "20px",
                    top: "10px",
                    width: "50%",
                    backgroundColor: "#032C65",
                    color: "white",
                    fontWeight: "normal",
                    border: "1px solid grey",
                    boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                  }}
                >
                  {t(`Confirm`)}
                </LoadingButton>
              </Grid>
            </>
          )}

          {step === 3 && (
            <>
              <Typography
                style={{
                  textAlign: "center",
                  color: "#032C65",
                  fontSize: "22px",
                  fontWeight: "normal",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                {t(`New password`)}
              </Typography>
              <input
                type="password"
                placeholder="NEW PASSWORD"
                onChange={(e) => setnewPass(e.target.value)}
                style={{
                  border: "1px solid grey",
                  textAlign: "center",
                  height: "10%",
                  borderRadius: " 4px ",
                  width: "100%",
                  fontSize: 16,
                  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                }}
                minLength={6}
              />

              <Typography
                style={{
                  textAlign: "center",
                  color: "#032C65",
                  fontSize: "22px",
                  fontWeight: "normal",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                {t(`Confirm new password`)}
              </Typography>
              <input
                type="password"
                placeholder="CONFIRM NEW PASSWORD"
                onChange={(e) => setConfirmPass(e.target.value)}
                style={{
                  border: "1px solid grey",
                  textAlign: "center",
                  height: "10%",
                  borderRadius: " 4px ",
                  width: "100%",
                  fontSize: 16,
                  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                }}
                minLength={6}
              />

              <LoadingButton
                variant="contained"
                type="submit"
                loading={running}
                onClick={changePass}
                style={{
                  marginTop: "20px",
                  top: "10px",
                  width: "50%",
                  backgroundColor: "#032C65",
                  color: "white",
                  fontWeight: "normal",
                  border: "1px solid grey",
                  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                }}
              >
                {t(`Confirm`)}
              </LoadingButton>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

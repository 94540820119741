import React, { useEffect, useState } from "react";
import "./Programs.css";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { apiUser } from "../../Apis/configs/axiosConfig";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AlarmIcon from "@mui/icons-material/Alarm";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { SpinnerCircular } from "spinners-react";
import Swal from "sweetalert2";
import * as dateFns from "date-fns";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  capitalize,
  Grid,
  IconButton,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { blue, blueGrey, green, grey, yellow } from "@mui/material/colors";
import { ArrowBack, CheckCircleRounded, ExpandMore } from "@mui/icons-material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const style = {
  bgcolor: "white",
  border: "2px solid grey",
  borderRadius: "8px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
  padding: "20px",
  textAlign: "center",
};

export const ProgramsEditView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [zones, setZones] = useState([]);
  const [newD, setNewD] = useState({});
  const [selectedWateringDays, setSelectedWateringDays] = useState({
    type: "odd",
    payload: "odd",
  });
  const [selectedRunTime, setSelectedRunTime] = useState({
    hour: "",
    minutes: "",
    seconds: "",
  });
  const [selectedStartTimes, setSelectedStartTimes] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [programSelected, setProgramSelected] = useState();
  const [pendingChanges, setPendingChanges] = useState({
    startTimes: false,
    wateringDays: false,
    runTimes: false,
  });
  const [currentContentVisible, setCurrentContentVisible] = useState(true);
  const [newContentVisible, setNewContentVisible] = useState(false);
  const [zonesCheckedList, setZonesCheckedList] = useState([]);
  const [groupsCheckedList, setGroupsCheckedList] = useState([]);
  const [zonesToRemoveList, setZonesToRemoveList] = useState([]);
  const [groupsToRemoveList, setGroupsToRemoveList] = useState([]);
  const [modalOption, setModalOption] = useState(0);
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [programGroups, setProgramGroups] = useState(null);
  const [isDisplayingZones, setIsDisplayingZones] = useState(true);
  const [selectedWateringDaysUnsaved, setselectedWateringDaysUnsaved] =
    useState(null);
    const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!!programSelected) {
      // verify start times
      let startTimesChanged = false;
      const programStartTimes = programSelected.start_times;
      for (let stIdx = 0; stIdx < selectedStartTimes.length; stIdx++) {
        const selSt = {
          active: selectedStartTimes[stIdx] !== "",
          ...selectedStartTimes[stIdx],
        };
        const curSt = programStartTimes[stIdx];

        const selValues = Object.values({
          hour: selSt.hour || 0,
          minutes: selSt.minutes || 0,
        }).join("");
        const curValues = Object.values(curSt.start_time).join("");

        // check first the active state
        const activeStateRemains = selSt.active === curSt.active;

        // when active state is the same, proceed to compare the values
        if (activeStateRemains) {
          startTimesChanged = selValues !== curValues;
        } else {
          startTimesChanged = true;
        }

        if (startTimesChanged) {
          break;
        }
      }

      // verify watering days
      let wateringDaysChanged = false;
      const programWateringDays = programSelected.watering_days;

      if (
        selectedWateringDays.type === "odd" ||
        selectedWateringDays.type === "even"
      ) {
        wateringDaysChanged =
          programWateringDays[selectedWateringDays.payload] !== true;
      } else if (selectedWateringDays.type === "cycle") {
        wateringDaysChanged =
          selectedWateringDays.payload !== programWateringDays.count_days;
      } else if (selectedWateringDays.type === "dow") {
        const parsedSelWd = [
          { active: selectedWateringDays.payload.monday },
          { active: selectedWateringDays.payload.tuesday },
          { active: selectedWateringDays.payload.wednesday },
          { active: selectedWateringDays.payload.thursday },
          { active: selectedWateringDays.payload.friday },
          { active: selectedWateringDays.payload.saturday },
          { active: selectedWateringDays.payload.sunday },
        ];
        (programWateringDays.days_of_week).forEach((wd, wdIdx) => {
          wateringDaysChanged =
            wateringDaysChanged || wd.active !== parsedSelWd[wdIdx].active;
        });
      }

      // verify run times
      let runTimesChanged = false;
      const programRuntimes = programSelected.run_times;

      if (zonesToRemoveList.length > 0) {
        runTimesChanged = true;
      } else {
        zonesCheckedList.forEach((rt) => {
          const programZoneRuntime = programRuntimes[rt.index];

          runTimesChanged =
            runTimesChanged ||
            rt.hour !== programZoneRuntime.hour ||
            rt.minutes !== programZoneRuntime.minutes ||
            rt.seconds !== programZoneRuntime.seconds;
        });
      }

      // if runtimes changed already, skip this and set state
      // if not, verify changes here as well
      if (!runTimesChanged) {
        if (groupsToRemoveList.length > 0) {
          runTimesChanged = true;
        } else {
          groupsCheckedList.forEach((groupData) => {
            if (groupData) {
              const groupZones = groupData.zones;
              const targetExampleZoneIndex = Math.min(...groupZones);
              const targetRuntime = zonesCheckedList.find(
                (item) => item.index === targetExampleZoneIndex - 1
              );

              // console.log(targetRuntime, groupData);

              if (targetRuntime) {

                runTimesChanged =
                  runTimesChanged ||
                  groupData.hour !== targetRuntime.hour ||
                  groupData.minutes !== targetRuntime.minutes ||
                  groupData.seconds !== targetRuntime.seconds;
              }


            }

          }
        );
        }
        
      }

      setPendingChanges({
        startTimes: startTimesChanged,
        wateringDays: wateringDaysChanged,
        runTimes: runTimesChanged,
      });
    }
  }, [
    selectedStartTimes,
    selectedWateringDays,
    zonesCheckedList,
    zonesToRemoveList,
    groupsCheckedList,
    groupsToRemoveList,
  ]);

  useEffect(() => {
    fetchZones();
  }, []);

  useEffect(() => {
    setLoading(true);

    try {
      if (zones.length > 0) {
        zonesByProgram();
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
    } finally {
    }
  }, [zones]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setProgramSelected(location.state.program);
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, [location.state.id, location.state.program]);
  useEffect(() => {
    if (programSelected) {
      // set start times
      let stList = selectedStartTimes;
      programSelected.start_times.forEach((item, stIdx) => {
        if (item.active) {
          stList[stIdx] = item.start_time;
        }
      });

      // // set watering days
      // let wdPayload = selectedWateringDays;
      // if (programSelected.watering_days.odd) {
      //   setSelectedWateringDays({ ...selectedWateringDays, payload: "odd" });
      // } else if (programSelected.watering_days.even) {
      //   setSelectedWateringDays({ ...selectedWateringDays, payload: "even" });
      // } else if (programSelected.watering_days.count_days > 0) {
      //   setSelectedWateringDays({
      //     ...selectedWateringDays,
      //     payload: programSelected.watering_days.count_days,
      //   });
      // } else {
      //   const newWd = {};
      //   programSelected.watering_days.days_of_week.forEach((item) => {
      //     newWd[item.day_of_week.name.toLowerCase()] = item.active;
      //   });
      //   setSelectedWateringDays({ type: "dow", payload: newWd });
      // }
      const newWd = {};
      programSelected.watering_days.days_of_week.forEach((item) => {
        newWd[item.day_of_week.name.toLowerCase()] = item.active;
      });
      setSelectedWateringDays({ type: "dow", payload: newWd });

      // set groups
      setProgramGroups(programSelected.groups);
    }
  }, [programSelected]);

  const fetchZones = async () => {
    try {
      const api = await apiUser.get(`/controllers/${location.state.id}`);
      const allZones = api.data.data.zones;

      setZones(allZones);
    } catch (e) {
      console.error(e);
    }
  };

  const zonesByProgram = () => {
    if (!programSelected) {
      return;
    }

    if (!programSelected.run_times) {
      return;
    }

    // set existing zone runtimes
    const existingZoneRuntimes = [];
    programSelected.run_times.map((zoneRuntime, index) => {
      const hasRuntime =
        zoneRuntime.hour > 0 ||
        zoneRuntime.minutes > 0 ||
        zoneRuntime.seconds > 0;

      if (hasRuntime) {
        existingZoneRuntimes.push({
          index: index,
          ...zones[index],
          ...zoneRuntime,
        });
      }

      return true;
    });

    try {
      setZonesCheckedList(existingZoneRuntimes);
    } catch (e) {
      console.error(e);
    }

    // set existing group run times
    const existingGroupRuntimes = [];
    Object.keys(programSelected.groups).forEach((groupKey) => {
      const groupData = programSelected.groups[groupKey];

      if (groupData) {
        const groupZones = groupData.zones;
        const targetExampleZoneIndex = Math.min(...groupZones);
        // console.log(targetExampleZoneIndex);
        const targetRuntime = zonesCheckedList.find(
          (item) => item.index === targetExampleZoneIndex - 1
        );

        if (targetRuntime) {
          existingGroupRuntimes.push({
            ...targetRuntime,
            ...groupData,
            zone_name: groupKey,
            is_group: true,
          });
        }
      }
    });

    try {
      setGroupsCheckedList(existingGroupRuntimes);
    } catch (e) {
      console.error(e);
    }
  };

  const hoursRuntime = (e) => {
    setSelectedRunTime((prevTime) => ({
      ...prevTime,
      hour: parseInt(e.target.value),
    }));
  };

  const minutesRuntime = (e) => {
    setSelectedRunTime((prevTime) => ({
      ...prevTime,
      minutes: parseInt(e.target.value),
    }));
  };

  const secondsRuntime = (e) => {
    setSelectedRunTime((prevTime) => ({
      ...prevTime,
      seconds: parseInt(e.target.value),
    }));
  };

  const editZoneTime = (obj) => {
    setModalOption(1);
    handleOpen();

    setNewD(obj);
    setSelectedRunTime({
      hour: obj.hour,
      minutes: obj.minutes,
      seconds: obj.seconds,
    });
  };

  const editGroupTime = (obj) => {
    setModalOption(1);
    handleOpen();

    // console.log(obj);

    setNewD({
      ...obj,
      zone_name: obj.name,
      is_group: true,
    });
    setSelectedRunTime({
      hour: obj.hour,
      minutes: obj.minutes,
      seconds: obj.seconds,
    });
  };

  const confirmEditGroupTime = () => {
    const newRunTimeInfo = { ...newD, ...selectedRunTime };

    // console.log([
    //   ...groupsCheckedList.filter(
    //     (item) => item.number !== newRunTimeInfo.number
    //   ),
    //   newRunTimeInfo,
    // ]);

    setGroupsCheckedList([
      ...groupsCheckedList.filter(
        (item) => item.number !== newRunTimeInfo.number
      ),
      newRunTimeInfo,
    ]);

    handleClose();
  };

  const confirmEditZoneTime = () => {
    const newRunTimeInfo = { ...newD, ...selectedRunTime };

    const newArr = [
      newRunTimeInfo,
      ...zonesCheckedList.filter((item) => item.index !== newRunTimeInfo.index),
    ];
    newArr.sort((a, b) => a.index - b.index);

    setZonesCheckedList(newArr);

    handleClose();
  };

  const isZoneAvailable = (zone) => {
    return zone.decoder !== "000000" ? true : false;
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleScheduleClick = () => {
    setCurrentContentVisible(false);
    setNewContentVisible(true);
  };

  const handleShowContentClick = () => {
    setCurrentContentVisible(true);
    setNewContentVisible(false);
  };

  const cycleDaysSelection = (e) => {
    const days = parseInt(e.target.value, 10);
    setSelectedWateringDays({ ...selectedWateringDays, ...{ payload: days } });
  };

  const wateringOptions = [
    // {
    //   name: "odd",
    //   label: "Odd Days",
    //   id: 1,
    // },
    // {
    //   name: "even",
    //   label: "Even Days",
    //   id: 2,
    // },
    // {
    //   name: "cycle",
    //   label: "Cycle Days",
    //   id: 3,
    // },
    {
      name: "dow",
      label: "Week Days",
      id: 4,
    },
  ];

  const handleCheckboxChange = (day) => {
    if (day.name === "even") {
      setSelectedWateringDays({ type: "even", payload: "even" });
    } else if (day.name === "odd") {
      setSelectedWateringDays({ type: "odd", payload: "odd" });
    } else if (day.name === "cycle") {
      if (selectedWateringDays.type !== "cycle") {
        setSelectedWateringDays({
          type: "cycle",
          payload:
            programSelected.watering_days.count_days > 0
              ? programSelected.watering_days.count_days
              : 1,
        });
      }
      setModalOption(2);
      setOpen(true);
    } else if (day.name === "dow") {
      setSelectedWateringDays({
        type: "dow",
        payload: {
          monday:
            selectedWateringDaysUnsaved &&
            selectedWateringDaysUnsaved.payload.monday
              ? true
              : false,
          tuesday:
            selectedWateringDaysUnsaved &&
            selectedWateringDaysUnsaved?.payload.tuesday
              ? true
              : false,
          wednesday:
            selectedWateringDaysUnsaved &&
            selectedWateringDaysUnsaved?.payload.wednesday
              ? true
              : false,
          thursday:
            selectedWateringDaysUnsaved &&
            selectedWateringDaysUnsaved?.payload.thursday
              ? true
              : false,
          friday:
            selectedWateringDaysUnsaved &&
            selectedWateringDaysUnsaved?.payload.friday
              ? true
              : false,
          saturday:
            selectedWateringDaysUnsaved &&
            selectedWateringDaysUnsaved?.payload.saturday
              ? true
              : false,
          sunday:
            selectedWateringDaysUnsaved &&
            selectedWateringDaysUnsaved?.payload.sunday
              ? true
              : false,
        },
      });
    }
  };
  const handleWeekDaySelection = (day) => {
    // console.log(day);
    const newWeekDayPayload = {
      ...selectedWateringDays.payload,
      ...{ [day]: !selectedWateringDays.payload[day] },
    };

    setSelectedWateringDays({
      ...selectedWateringDays,
      payload: newWeekDayPayload,
    });
    setselectedWateringDaysUnsaved({
      ...selectedWateringDaysUnsaved,
      payload: newWeekDayPayload,
    });
  };
  // console.log(selectedWateringDaysUnsaved);

  const handleGroupClick = (groupNumber, groupKey) => {
    const groupData = programGroups[groupKey];

    const groupIndex = groupsCheckedList.findIndex(
      (groupCard) => groupCard.number === groupNumber
    );

    // (least numbered station's run time)

    if (groupIndex !== -1) {
      Swal.fire({
        title: t("Remove Group"),
        text: t(`Do you want to remove group:`) +` ${groupKey}`,
        icon: "question",
        confirmButtonText: t("Yes, remove it"),
        confirmButtonColor: "#032C65",
        cancelButtonText: t("Keep it"),
      }).then((result) => {
        if (result.isConfirmed) {
          const updatedGroups = groupsCheckedList
            .map((item, index) => (item.number === groupNumber ? null : item))
            .filter((item) => !!item);

          setGroupsCheckedList(updatedGroups);
          setGroupsToRemoveList([...groupsCheckedList, groupData]);

          // update zones checked to avoid showing when their group is removed
          setZonesCheckedList([
            ...zonesCheckedList.filter(
              (zoneChecked) =>
                !groupData.zones.some(
                  (zNumber) => zNumber === zoneChecked.index + 1
                )
            ),
          ]);
        }
      });
    } else {
      setGroupsToRemoveList([
        ...groupsToRemoveList.filter((item) => item.number !== groupNumber),
      ]);
      setGroupsCheckedList([
        ...groupsCheckedList,
        { ...groupData, name: groupKey, hour: 0, minutes: 0, seconds: 0 },
      ]);
    }
  };

  const handleZoneClick = (index) => {
    const selectedZone = zones[index];
    selectedZone.index = index;

    selectedZone.hour = 0;
    selectedZone.minutes = 0;
    selectedZone.seconds = 0;

    const zoneLabel = selectedZone.zone_name
      ? selectedZone.zone_name
      : t(`Zone`) +` ${selectedZone.index + 1}`;

    const zoneIndex = zonesCheckedList.findIndex(
      (zoneCard) => zoneCard.decoder === selectedZone.decoder
    );

    if (zoneIndex !== -1) {
      Swal.fire({
        title: t("Remove Zone"),
        text: t(`Do you want to remove zone:`) + ` ${zoneLabel}`,
        icon: "question",
        confirmButtonText: t("Yes, remove it"),
        confirmButtonColor: "#032C65",
        cancelButtonText: t("Keep it"),
      }).then((result) => {
        if (result.isConfirmed) {
          const updatedZones = zonesCheckedList
            .map((item, index) => (index === zoneIndex ? null : item))
            .filter((item) => !!item);

          setZonesCheckedList(updatedZones);
          setZonesToRemoveList([...zonesToRemoveList, selectedZone]);
        }
      });
    } else {
      setZonesToRemoveList([
        ...zonesToRemoveList.filter(
          (item) => item.index !== selectedZone.index
        ),
      ]);
      const newArr = [...zonesCheckedList, selectedZone];
      newArr.sort((a, b) => a.index - b.index);
      setZonesCheckedList(newArr);
    }
  };

  const confirmWateringDays = async () => {
    const data = {
      program: location.state.program.letter,
      watering_days: selectedWateringDays.payload,
    };

    try {
      const wateringDaysUpdateResponse = await apiUser.put(
        `/controllers/${location.state.id}/update_schedule/watering_days`,
        data
      );
    } catch (e) {
      console.error(e);
    }
  };

  const confirmRunTimes = async () => {
    const zonesToUpdate = {};

    // add zones to add/change their run time
    zonesCheckedList.forEach((item) => {
      if (
        !zonesToRemoveList.find(
          (itemToRemove) => item.index === itemToRemove.index
        )
      ) {
        zonesToUpdate[item.index + 1] = {
          zone_number: item.index + 1,
          hours: item.hour,
          minutes: item.minutes,
          seconds: item.seconds,
        };
      }
    });

    // add zones to remove their run time on the given program
    zonesToRemoveList.forEach(
      (item) =>
        (zonesToUpdate[item.index + 1] = {
          zone_number: item.index + 1,
          hours: 0,
          minutes: 0,
          seconds: 0,
        })
    );

    // parse groups
    groupsCheckedList.forEach((groupData) => {
      groupData.zones.forEach((zoneNumber) => {
        zonesToUpdate[zoneNumber] = {
          zone_number: zoneNumber,
          hours: groupData.hour,
          minutes: groupData.minutes,
          seconds: groupData.seconds,
        };
      });
    });

    groupsToRemoveList.forEach((groupData) => {
      groupData.zones.forEach((zoneNumber) => {
        zonesToUpdate[zoneNumber] = {
          zone_number: zoneNumber,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      });
    });

    const runtimeUpdatePayload = {
      program: location.state.program.letter,
      run_times: {
        zones: Object.values(zonesToUpdate),
      },
    };

    try {
      const request = await apiUser.put(
        `/controllers/${location.state.id}/update_schedule/run_times`,
        runtimeUpdatePayload
      );

      setZonesToRemoveList([]);
      setGroupsToRemoveList([]);
    } catch (e) {
      console.error(e);
    }
  };

  const saveEditProgram = async () => {
    setLoadingSchedule(true);

    Promise.all([confirmWateringDays(), confirmStartTimes(), confirmRunTimes()])
      .then((results) => {
        Swal.fire({
          title: t("Changes saved!"),
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
          background: "#f7f8fa",
        });
      })
      .catch((err) => {
        console.error(err);

        Swal.fire({
          title: t("Error, something went wrong"),
          icon: "warning",
          timer: 2000,
          showConfirmButton: false,
        });
      })
      .finally(() => {
        setLoadingSchedule(false);
      });
  };

  const toggleStartTime = (stIndex) => {
    let currStList = selectedStartTimes;
    const targetSt = currStList[stIndex];

    if (targetSt === "") {
      currStList[stIndex] = { hour: 0, minutes: 0 };
    } else {
      currStList[stIndex] = "";
    }

    // console.log([...currStList]);

    setSelectedStartTimes(() => [...currStList]);
  };

  const handleTimeChange = (stIndex, newRawTime) => {
    try {
      // // Mar 18 2024 13:08:00 GMT-0400
      const parsedDate = new Date(newRawTime);

      const newTimeObj = {
        hour: parseInt(dateFns.format(parsedDate, "H")),
        minutes: parseInt(dateFns.format(parsedDate, "m")),
      };

      let newStartTimesList = selectedStartTimes;
      newStartTimesList[stIndex] = newTimeObj;

      setSelectedStartTimes([...newStartTimesList]);
      // setStartTimes((prevTimes) => ({
      //   ...prevTimes,
      //   [`time${stIndex + 1}`]: newTimeObj,
      // }));
    } catch (err) {
      console.error(err);
    }
  };

  const confirmStartTimes = async () => {
    if (pendingChanges.startTimes && programSelected) {
      try {
        const formattedData = {
          program: programSelected.letter,
          start_times: selectedStartTimes.map((item) => ({
            hour: item === "" ? 24 : item.hour,
            minutes: item === "" ? 0 : item.minutes,
          })),
        };
        const startTimesUpdateResponse = await apiUser.put(
          `/controllers/${location.state.id}/update_schedule/start_times`,
          formattedData
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <Grid item xs={12} container justifyContent="center">
      <Grid
        sx={{
          borderRadius: "8px",
          border: `1px solid ${grey[300]}`,
          backgroundColor: "#F7F8FA",
          padding: 2,
        }}
        item
        container
        component={Paper}
        elevation={0}
        xs={11}
        sm={10}
        justifyContent="space-between"
      >
        <Grid
          item
          container
          xs={12}
          sm={5}
          md={4}
          justifyContent="space-between"
        >
          <Grid
            item
            xs={12}
            sm={1.5}
            alignItems="center"
            component={Stack}
            justifyContent="center"
          >
            <IconButton
              size="large"
              sx={{ backgroundColor: "white" }}
              onClick={() => {
                const newState = location.state;
                newState.program = null;

                navigate(`/dashboard/${location.state.id}`, {
                  state: newState,
                });
              }}
            >
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={10.5}
            alignItems="center"
            component={Stack}
            justifyContent="space-evenly"
          >
            <Typography variant="h6" fontWeight="bolder" color="#6B7A99">
                {location.state.program.id} - {t(`Edit`)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={7} justifyContent="space-between">
          <Grid item>
            <Button
              variant="text"
              size="large"
              onClick={handleScheduleClick}
              sx={{
                backgroundColor: "white",
                fontWeight: "bold",
                textTransform: "capitalize",
                boxShadow: newContentVisible ? 4 : 0,
                zIndex: newContentVisible ? 1 : 0,
                color: newContentVisible ? green[500] : blueGrey[600],
              }}
            >
              {t(`Schedule`)}
            </Button>
            <Button
              variant="text"
              size="large"
              onClick={handleShowContentClick}
              sx={{
                backgroundColor: "white",
                fontWeight: "bold",
                textTransform: "capitalize",
                color: currentContentVisible ? green[500] : blueGrey[600],
                boxShadow: currentContentVisible ? 4 : 0,
                zIndex: currentContentVisible ? 1 : 0,
              }}
            >
              {t(`Run times`)}
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              loading={loadingSchedule}
              className="button-new-controller-style"
              variant="contained"
              onClick={() => saveEditProgram()}
              disabled={
                !(
                  pendingChanges.runTimes ||
                  pendingChanges.startTimes ||
                  pendingChanges.wateringDays
                )
              }
              size="large"
              sx={{
                backgroundColor: "white",
                fontWeight: "bold",
                textTransform: "capitalize",
                boxShadow: pendingChanges ? 4 : 0,
                color: blueGrey[600],
                borderRadius: 4,
                "&:hover": {
                  backgroundColor: "white",
                  boxShadow: 6,
                  color: blue[600],
                },
              }}
              startIcon={
                !loadingSchedule && (
                  <SaveIcon
                    style={{
                      fontSize: 25,
                      color: pendingChanges ? yellow[600] : "white",
                    }}
                  />
                )
              }
            >
              {t(`Save`)}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={11}
        sm={10}
        justifyContent="space-evenly"
        paddingY={4}
      >
        {newContentVisible && (
          <Grid container justifyContent="space-evenly">
            <Grid
              item
              xs={12}
              md={4}
              component={Paper}
              elevation={6}
              borderRadius={6}
              padding={3}
              marginBottom={4}
            >
              <Grid
                item
                container
                xs={12}
                direction="row"
                justifyContent="center"
                alignContent="center"
              >
                <Grid item xs={3}>
                  <CalendarMonthIcon
                    fontSize="large"
                    sx={{ color: "#032C65", textAlign: "center" }}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="h6"
                    color="#032C65"
                    fontWeight="bold"
                    textAlign="start"
                  >
                    {t(`Watering Days`)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={location.state.program.watering_days}
                  name="radio-buttons-group"
                  style={{
                    alignContent: "flex-start",
                    position: "relative",
                    marginTop: "2%",
                  }}
                >
                  {wateringOptions.map((option) => (
                    <Grid
                      key={`wd-option-${option.id}`}
                      container
                      sx={{
                        color: "#032C65",
                        fontWeight: "normal",
                        border: `2px solid ${grey[300]}`,
                        paddingX: 2,
                        marginBottom: 1,
                        borderRadius: 2,
                        justifyContent: "start",
                      }}
                    >
                      <Grid item xs={12} textAlign="start">
                        <FormControlLabel
                          key={option.id}
                          value={option.name}
                          control={<Radio />}
                          sx={{ display: "none" }}
                          label={
                            option.name === "cycle" &&
                            selectedWateringDays.type === "cycle"
                              ? `Cycle: ${selectedWateringDays.payload} day${
                                  selectedWateringDays.payload > 1 ? "s" : ""
                                }`
                              : option.label
                          }
                          onClick={() => handleCheckboxChange(option)}
                          // onChange={() => handleCheckboxChange(option)}
                          checked={
                            selectedWateringDays &&
                            option.name === selectedWateringDays.type
                          }
                        />
                      </Grid>
                      {selectedWateringDays.type === "dow" &&
                      option.name === "dow" ? (
                        <FormControl
                          component="fieldset"
                          style={{
                            textAlign: "center",
                            marginTop: "2%",
                            textTransform: "capitalize",
                          }}
                        >
                        {Object.keys(selectedWateringDays.payload).map(
  (day) => (
    <FormControlLabel
      key={`dow-option-${day}`}
      value={day}
      style={{
        color: "#032C65",
        fontWeight: "normal",
        textTransform: "capitalize",
      }}
      control={<Checkbox />}
      label={t(day.charAt(0).toUpperCase() + day.slice(1))}
      checked={selectedWateringDays.payload[day]}
      onChange={() => handleWeekDaySelection(day)}
    />
  )
)}

                        </FormControl>
                      ) : (
                        ""
                      )}
                    </Grid>
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={7.5}
              component={Paper}
              elevation={6}
              borderRadius={6}
              paddingY={3}
              paddingX={1}
            >
              <Grid
                item
                container
                xs={12}
                direction="row"
                justifyContent="center"
                alignContent="center"
              >
                <Grid item container xs={2} justifyContent="start">
                  <AlarmIcon
                    style={{ color: "#032C65", textAlign: "start" }}
                    fontSize="large"
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="h6"
                    color="#032C65"
                    fontWeight="bold"
                    textAlign="start"
                  >
                    {t(`Start Times`)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                paddingY={2}
                marginBottom={20}
              >
                {selectedStartTimes.map((timeKey, index) => (
                  <Grid
                    item
                    xs={5.6}
                    md={5.7}
                    margin={0}
                    key={`start-time-${index}`}
                  >
                    <Accordion
                      defaultExpanded={true}
                      disableGutters={true}
                      elevation={0}
                      sx={{
                        backgroundColor: blueGrey[50],
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography
                          fontWeight="bold"
                          color={timeKey === "" ? blueGrey[700] : green[600]}
                          display="block"
                        >
                          {timeKey !== "" ? "✅" : ""} {t(`Start time`)} {index + 1}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: blueGrey[50],
                          paddingTop: 0,
                        }}
                      >
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item xs={12} lg={3.6}>
                            <FormControlLabel
                              sx={{ fontWeight: "bold" }}
                              control={
                                <Switch
                                  checked={timeKey !== ""}
                                  onClick={() => toggleStartTime(index)}
                                />
                              }
                              label={timeKey !== "" ? t("ON") : t("OFF")}
                            />
                          </Grid>
                          <Grid item xs={12} lg={7.5}>
                            <DesktopTimePicker
                              sx={{
                                backgroundColor: "white",
                                ".MuiPickersToolbar-content": {
                                  fontSize: "0.3em",
                                },
                              }}
                              timeSteps={{
                                hours: 1,
                                minutes: 1,
                                seconds: 1,
                              }}
                              value={
                                timeKey === ""
                                  ? null
                                  : dateFns.parse(
                                      [
                                        timeKey.hour
                                          .toString()
                                          .padStart(2, "0"),
                                        timeKey.minutes
                                          .toString()
                                          .padStart(2, "0"),
                                      ].join(":"),
                                      "HH:mm",
                                      new Date()
                                    )
                              }
                              disabled={timeKey === ""}
                              onChange={(newValue) =>
                                handleTimeChange(index, newValue)
                              }
                              onAccept={(newValue) =>
                                handleTimeChange(index, newValue)
                              }
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
        {currentContentVisible && (
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={6}
              md={7.8}
              sx={{ backgroundColor: "white", borderRadius: 6, paddingX: 2 }}
            >
              <Grid item paddingTop={2}>
                <Typography
                  fontWeight="bold"
                  color="#032C65"
                  textAlign="center"
                >
                  {t(`Zones`)} & {t(`Run times`)}
                </Typography>
              </Grid>
              <Grid
                item
                container
                paddingTop={2}
                justifyContent="space-evenly"
                sx={{
                  overflowY: "scroll",
                  maxHeight: "50vh",
                  marginBottom: 1,
                }}
              >
                <Grid
                  item
                  container
                  paddingTop={2}
                  justifyContent="start"
                  sx={{
                    marginBottom: 1,
                  }}
                >
                  {loading ? (
                    <SpinnerCircular />
                  ) : (
                    groupsCheckedList.map((groupData, index) => {
                      return (
                        <Grid
                          key={`zone-runtime-${index}`}
                          item
                          xs={12}
                          sm={5.3}
                          component={Paper}
                          elevation={4}
                          margin={1}
                          padding={1}
                          borderRadius={5}
                          onClick={() => editGroupTime(groupData)}
                          sx={{ height: "auto" }}
                          title={`Group run time for: ${groupData.zone_name}`}
                        >
                          <Grid
                            container
                            justifyContent="center"
                            alignContent="center"
                          >
                            {groupData.zones.map((zoneNumber) => (
                              <Avatar
                                key={`group-${groupData.number}-${zoneNumber}`}
                                sx={{
                                  backgroundColor: "#14b549",
                                  width: 30,
                                  height: 30,
                                  margin: 0.5,
                                }}
                                title={
                                  zones
                                    ? zones[zoneNumber - 1].zone_name
                                    : t(`Zone`) + `${zoneNumber}`
                                }
                              >
                                <Typography fontWeight="bolder">
                                  {zoneNumber}
                                </Typography>
                              </Avatar>
                            ))}
                          </Grid>
                          <Grid
                            container
                            justifyContent="center"
                            direction="column"
                            sx={{ backgroundColor: "white" }}
                          >
                            <Grid item container justifyContent="center">
                              <Typography variant="caption">
                                {t(`Group`)}:&nbsp;
                              </Typography>
                              <Typography fontWeight="bold" variant="caption">
                                {groupData.zone_name}
                              </Typography>
                            </Grid>
                            <Grid item container justifyContent="center">
                              <Typography
                                variant="body"
                                fontWeight="bold"
                                textAlign="center"
                                marginTop={1}
                              >
                                {groupData.hour.toString().padStart(2, "0")}:
                                {groupData.minutes.toString().padStart(2, "0")}:
                                {groupData.seconds.toString().padStart(2, "0")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })
                  )}
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="start"
                  sx={{
                    marginBottom: 2,
                  }}
                >
                  {zonesCheckedList.map((zone, index) => {
                    // if there is a group on the program that contains this zone,
                    // then this zone cannot be shown (all will be handled in the update request)
                    const canShowZone = !groupsCheckedList.some((item) => {
                      return item.zones.some((item) => item === zone.index + 1);
                    });

                    const hasNoRuntime =
                      zone.hour === 0 &&
                      zone.minutes === 0 &&
                      zone.seconds === 0;

                    return (
                      canShowZone && (
                        <Grid
                          key={`zone-runtime-${index}`}
                          item
                          xs={12}
                          sm={5}
                          md={4}
                          lg={12 / 5}
                          component={Paper}
                          elevation={4}
                          margin={1}
                          padding={1}
                          borderRadius={5}
                          onClick={() => editZoneTime(zone)}
                          sx={{
                            height: "auto",
                            backgroundColor: hasNoRuntime
                              ? yellow[100]
                              : "white",
                          }}
                        >
                          <Grid
                            container
                            justifyContent="center"
                            alignContent="center"
                          >
                            <Avatar
                              sx={{
                                backgroundColor: "#14b549",
                                width: 30,
                                height: 30,
                              }}
                            >
                              <Typography fontWeight="bolder">
                                {zone ? Number(zone.index) + 1 : ""}
                              </Typography>
                            </Avatar>
                          </Grid>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            direction="column"
                          >
                            <Grid item>
                              <Typography fontWeight="bold" variant="caption">
                                {zone.zone_name
                                  ? zone.zone_name
                                  : t(`Zone`) + `${zone.index + 1}`}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="caption">
                                {t(`Line`)} {zone.zone_wire}
                              </Typography>
                            </Grid>
                            <Grid item container justifyContent="center">
                              <Typography
                                variant="body"
                                fontWeight="bold"
                                textAlign="center"
                                marginTop={1}
                              >
                                {zone.hour.toString().padStart(2, "0")}:
                                {zone.minutes.toString().padStart(2, "0")}:
                                {zone.seconds.toString().padStart(2, "0")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={5}
              md={4}
              sx={{
                backgroundColor: "white",
                borderRadius: 6,
                paddingX: 1,
                paddingBottom: 2,
              }}
            >
              <Grid container justifyContent="center">
                <Grid item marginTop={2}>
                  <Button
                    variant="text"
                    size="large"
                    onClick={() => setIsDisplayingZones(true)}
                    sx={{
                      backgroundColor: "white",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      boxShadow: isDisplayingZones ? 4 : 0,
                      zIndex: isDisplayingZones ? 1 : 0,
                      color: isDisplayingZones ? green[500] : blueGrey[600],
                    }}
                  >
                    {t(`Zones`)}
                  </Button>
                  {programGroups && Object.keys(programGroups).length > 0 && (
                    <Button
                      variant="text"
                      size="large"
                      onClick={() => setIsDisplayingZones(false)}
                      sx={{
                        backgroundColor: "white",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        color: !isDisplayingZones ? green[500] : blueGrey[600],
                        boxShadow: !isDisplayingZones ? 4 : 0,
                        zIndex: !isDisplayingZones ? 1 : 0,
                      }}
                    >
                      {t(`Groups`)}
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} paddingY={2} textAlign="center">
                <Typography fontWeight="bold" color="#032C65">
                 {isDisplayingZones ? t("Available Zones") : t("Available Groups")}
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent="center"
                sx={{
                  overflowY: "scroll",
                  maxHeight: "50vh",
                }}
              >
                {isDisplayingZones ? (
                  zones ? (
                    zones
                      .map((item, index) => ({ ...item, number: index + 1 }))
                      .filter((item) => isZoneAvailable(item))
                      .map((item, index) => {
                        const isChecked =
                          zonesCheckedList.find(
                            (cz) => cz.decoder === item.decoder
                          ) ||
                          groupsCheckedList.find((gr) =>
                            gr.zones.find((zn) => zn === item.number)
                          );

                        return (
                          <Grid
                            item
                            container
                            xs={12}
                            sm={5}
                            md={4.5}
                            component={Paper}
                            key={`available-zone-${index}`}
                            onClick={() => handleZoneClick(item.number - 1)}
                            sx={{
                              margin: 1,
                              paddingY: 1,
                              paddingX: 0.5,
                              borderRadius: 2,
                              color: isChecked ? green[700] : blueGrey[700],
                              border: isChecked
                                ? `1px solid ${green[700]}`
                                : "",
                            }}
                            elevation={isChecked ? 4 : 1}
                            justifyContent="space-evenly"
                            alignContent="center"
                          >
                            {isChecked && (
                              <CheckCircleRounded color="success" />
                            )}
                            <Typography variant="body" fontWeight="bold">
                              {item.zone_name
                                ? item.zone_name
                                : t(`Zone`) + ` ${item.number}`}
                            </Typography>
                          </Grid>
                        );
                      })
                  ) : loading ? (
                    <SpinnerCircular />
                  ) : (
                    <p>{t(`NO ZONES FOUND`)}</p>
                  )
                ) : (
                  Object.keys(programGroups).map((groupName, index) => {
                    const groupData = programGroups[groupName];
                    const isChecked = groupsCheckedList.find(
                      (group) => group.number === groupData.number
                    );


                    return (
                      <Grid
                        item
                        container
                        xs={12}
                        sm={5.5}
                        md={5.2}
                        component={Paper}
                        key={`available-group-${index}`}
                        onClick={() =>
                          handleGroupClick(groupData.number, groupName)
                        }
                        sx={{
                          margin: 1,
                          paddingY: 1,
                          paddingX: 0.5,
                          borderRadius: 2,
                          color: isChecked ? green[700] : blueGrey[700],
                          border: isChecked ? `1px solid ${green[700]}` : "",
                        }}
                        elevation={isChecked ? 4 : 1}
                        justifyContent="space-evenly"
                        alignContent="center"
                      >
                        {isChecked && <CheckCircleRounded color="success" />}
                        <Typography
                          variant="body"
                          fontWeight="bold"
                          textOverflow="ellipsis"
                        >
                          {groupName}
                        </Typography>
                        {!isChecked && (
                          <Grid
                            container
                            justifyContent="center"
                            alignContent="center"
                          >
                            {groupData.zones.map((zoneNumber) => (
                              <Avatar
                                key={`group-selection-${groupData.number}-${zoneNumber}`}
                                sx={{
                                  backgroundColor: "#212C62",
                                  width: 15,
                                  height: 15,
                                  padding: 0.5,
                                  margin: 0.5,
                                }}
                                title={
                                  zones
                                    ? zones[zoneNumber - 1].zone_name
                                    : t(`Zone`) + `${zoneNumber}`
                                }
                              >
                                <Typography variant="caption" fontWeight="bold">
                                  {zoneNumber}
                                </Typography>
                              </Avatar>
                            ))}
                          </Grid>
                        )}
                      </Grid>
                    );
                  })
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container justifyContent="center" paddingTop={10}>
          <Grid item xs={10} md={7}>
            <Box sx={style}>
              <Grid container justifyContent="end">
                <CloseIcon
                  size="large"
                  color="primary"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                />
              </Grid>
              <Typography
                style={{ textAlign: "center", color: "#032C65" }}
                id="modal-modal-title"
                variant="h5"
                component="h2"
                fontWeight="bold"
              >
                {modalOption === 1 ? t("Run Time") : t("Cycle Days")}
              </Typography>
              {modalOption === 1 && (
                <Typography
                  style={{ textAlign: "center", color: "#032C65" }}
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                >
                  {newD.zone_name ? newD.zone_name : t(`Zone`) +` ${newD.index + 1}`}
                </Typography>
              )}
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: 30,
                    marginBottom: 30,
                  }}
                >
                  {modalOption === 1 ? (
                    <div
                      style={{
                        display: "flex",
                        direction: "row",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          alignContent: "center",
                          textAlign: "center",
                          paddingLeft: 5,
                          paddingRight: 5,
                        }}
                      >
                        <label
                          style={{
                            marginRight: "10px",
                            display: "block",
                            color: "#032C65",
                          }}
                          htmlFor="hours"
                        >
                          {t(`Hours`)}
                        </label>
                        <select
                          id="hours"
                          name="hours"
                          value={selectedRunTime.hour}
                          style={{
                            width: "100%",
                            height: 40,
                            textAlign: "center",
                            fontWeight: "normal",
                          }}
                          onChange={hoursRuntime}
                        >
                          {Array.from({ length: 12 }, (_, i) => i).map(
                            (hour) => (
                              <option key={hour} value={hour}>
                                {hour}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div
                        style={{
                          alignContent: "center",
                          textAlign: "center",
                          paddingLeft: 5,
                          paddingRight: 5,
                        }}
                      >
                        <label
                          style={{
                            marginRight: "10px",
                            display: "block",
                            color: "#032C65",
                          }}
                          htmlFor="minute"
                        >
                          {t(`Minutes`)}
                        </label>
                        <select
                          id="minute"
                          name="minutes"
                          value={selectedRunTime.minutes}
                          style={{
                            width: "100%",
                            fontWeight: "normal",
                            height: 40,
                            textAlign: "center",
                          }}
                          onChange={minutesRuntime}
                        >
                          {Array.from({ length: 60 }, (_, i) => i).map(
                            (minute) => (
                              <option key={minute} value={minute}>
                                {minute}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div
                        style={{
                          alignContent: "center",
                          textAlign: "center",
                          paddingLeft: 5,
                          paddingRight: 5,
                        }}
                      >
                        <label
                          style={{
                            marginRight: "10px",
                            display: "block",
                            color: "#032C65",
                          }}
                          htmlFor="second"
                        >
                          {t(`Seconds`)}
                        </label>
                        <select
                          id="second"
                          name="seconds"
                          value={selectedRunTime.seconds}
                          style={{
                            width: "100%",
                            fontWeight: "normal",
                            height: 40,
                            textAlign: "center",
                          }}
                          onChange={secondsRuntime}
                        >
                          {Array.from({ length: 60 }, (_, i) => i).map(
                            (second) => (
                              <option key={second} value={second}>
                                {second}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  ) : (
                    <>
                      <label
                        style={{ marginRight: "10px", color: "#032C65" }}
                        htmlFor="cycleDays"
                      >
                        {t(`Days`)}:
                      </label>
                      <select
                        id="cycledays"
                        name="cycledays"
                        value={selectedWateringDays.payload}
                        style={{
                          width: "20%",
                          height: 40,
                          textAlign: "center",
                          borderRadius: "5px",
                        }}
                        onChange={cycleDaysSelection}
                      >
                        {Array.from({ length: 32 }, (_, i) => i).map((days) => (
                          <option key={days} value={days}>
                            {days}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </div>
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <LoadingButton
                    variant="contained"
                    onClick={
                      modalOption === 1
                        ? newD.is_group
                          ? confirmEditGroupTime
                          : confirmEditZoneTime
                        : handleClose
                    }
                    style={{
                      width: "50%",
                      backgroundColor: "#032C65",
                      color: "white",
                    }}
                  >
                    {t(`Confirm`)}
                  </LoadingButton>
                </div>
              </>
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};

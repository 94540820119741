import * as Yup from "yup";

export const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Too Short! Password must be at lest 6 characters long")
    .max(50, "Too Long!")
    .required("Required"),
  repeat_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match")
    .required("Required"),
  last_name: Yup.string()
    .min(2, "Last name must be at least 2 characters long")
    .max(50, "Too Long!")
    .required("Required"),
  first_name: Yup.string()
    .min(2, "First name must be at least 2 characters long")
    .max(50, "Too Long!")
    .required("Required"),
  company_name: Yup.string()
    .min(1, "Must be at least 1 character long")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Required")
    .min(1, "Too short"),
});

export default SignupSchema;

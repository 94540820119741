import React, { useCallback, useEffect, useState } from "react";
import { apiUser } from "../../Apis/configs/axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGrid, GridExpandMoreIcon } from "@mui/x-data-grid";
import { SpinnerCircular } from "spinners-react";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Chip,
  IconButton,
  Stack,
  Paper,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { green, grey } from "@mui/material/colors";
import { ArrowBack } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from 'react-i18next';

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: "8px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  padding: "20px",
  textAlign: "center",
};

const labelStyle = {
  fontSize: "18px",
  fontWeight: "normal",
  marginBottom: "10px",
  color: "#032C65",
};

export const ZonesTable = () => {
  const [zones, setZones] = useState([]);
  const [selectedDecorerTypes, setSelectedDecorerTypes] = useState([]);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const columns = [
    {
      field: "zone_type",
      headerName: t("Decoder Type"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      align: "left",
      headerClassName: "header-table-title-style",
      renderCell: ({ row }) => {
        let label = "";
        switch (row.zone_type) {
          case 1:
            label = t("Zone");
            break;
          case 2:
            label = "MV/PS";
            break;
          case 3:
            label = t("Flow meter");
            break;
          case 4:
            label = t("Moisture sensor");
            break;

          default:
        }
        return row.decoder !== "000000" ? (
          <Chip
            label={label}
            size="small"
            sx={{ backgroundColor: green[100] }}
          />
        ) : (
          <Chip
            label="N/A"
            size="small"
            sx={{
              backgroundColor: grey[200],
              color: grey[600],
            }}
          />
        );
      },
    },
    {
      field: "zone_name",
      headerName: t("Name"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
    },
    {
      field: "decoder",
      headerName: t("Decoder S/N"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
    },
    {
      field: "zone_wire",
      headerName: t("Wire"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
    },
    {
      field: "edit",
      headerName: t("Actions"),
      headerClassName: "header-table-title-style",
      renderCell: (params) => (
        <Button
          startIcon={<EditIcon />}
          style={{ alignContent: "flex-start", color: "#032C65" }}
          onClick={() => editZone(params.row)}
        ></Button>
      ),
    },
  ];

  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    decoder: "",
    wire: "",
    Decodertype: 1,
  });
  const [loadingBox, setloadingBox] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      getZones();
    } catch (e) {
      console.error(e);
    }
  }, []);

  const getZones = async () => {
    setIsLoading(true);
    try {
      const zonesResponse = await apiUser.get(
        `/controllers/${location.state.id}`
      );
      const zonesData = zonesResponse.data.data.zones;

      const zonesWithIds = zonesData.map((zone, index) => ({
        ...zone,
        id: index + 1,
        zone_name: zone.zone_name || t(`Zone`) +` ${index + 1}`,
      }));
      setZones(zonesWithIds);
      setSelectedDecorerTypes(new Array(zonesData.length).fill(""));
    } catch (error) {
      console.error(error);
      if (error) {
        return Swal.fire({
          title: "Error",
          text: t("Error occured, back to my controllers."),
          icon: "warning",
          timer: 2000,
        }).then(() => {
          navigate("/mycontrollers");
        });
      }
    }
    setIsLoading(false);
  };

  const openBox = () => setOpen(true);

  const closebox = () => setOpen(false);

  const editZone = (item) => {
    openBox();
    setSelectedRow(item);
    setFormData({
      name: item.zone_name || "",
      decoder: item.decoder || "",
      wire: item.zone_wire || "",
      Decodertype: item.zone_type || 1,
    });
  };

  const handle_formChanges = useCallback(
    (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    },
    [formData]
  );

  const MobileTable = ({ data }) => (
    <Grid item container xs={12} justifyContent="center">
      {data.map((row) => (
        <Grid item xs={10} sm={8} key={`zone-mobile-table-${row.id}`}>
          <Accordion
            style={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid grey",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(5, 11, 0, 0.3)",
              marginBottom: "16px",
            }}
            key={`zone-list-item-${row.id}`}
          >
            <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
              <Grid item container xs={12}>
                <Grid item container xs={4}>
                  <Typography
                    style={{
                      display: "flex",
                      color: "#032C65",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      fontWeight: "bold",
                    }}
                  >
                    {row.zone_name ? row.zone_name : row.id}
                  </Typography>
                </Grid>
                <Grid item container xs={8}>
                  <Typography
                    style={{
                      display: "flex",
                      color: "#032C65",
                      flexDirection: "column",
                      fontFamily: "monospace",
                      alignItems: "flex-start",
                      fontWeight: "normal",
                    }}
                  >
                    {row.decoder}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails
              style={{
                color: "#032C65",
                fontWeight: "normal",
                marginRight: "8px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              <Typography
                style={{
                  color: "#032C65",
                  fontWeight: "normal",
                }}
              >
                {t(`Zone Name`)}:
              </Typography>
              <Typography
                style={{
                  color: "black",
                  marginRight: "8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >{`${row.zone_name || "N/A"}`}</Typography>
              <Typography
                style={{
                  color: "#032C65",
                  fontWeight: "normal",
                }}
              >
                {t(`Decoder S/N`)}
              </Typography>
              <Typography
                style={{
                  color: "black",
                  fontSize: "13px",
                  marginRight: "8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >{`${row.decoder || "N/A"}`}</Typography>
              <Typography
                style={{
                  color: "#032C65",
                  fontWeight: "normal",
                }}
              >
                {t(`Decoder Type`)}:
              </Typography>

              {row.decoder !== "000000" ? (
                <Chip
                  label={
                    row.zone_type === 1
                      ? t("Zone")
                      : row.zone_type === 2
                      ? "MV/PS"
                      : row.zone_type === 3
                      ? t("Flow meter")
                      : t("Moisture sensor")
                  }
                  size="small"
                  sx={{
                    backgroundColor:
                      row.zone_type === 1 ? green[100] : grey[200],
                    marginBottom: 2,
                  }}
                />
              ) : (
                <Chip
                  label="N/A"
                  size="small"
                  sx={{ backgroundColor: grey[200], color: grey[600] }}
                />
              )}
              <Typography
                style={{
                  color: "#032C65",
                  fontWeight: "normal",
                }}
              >
                {t(`Wire`)}:
              </Typography>
              <Typography
                style={{
                  color: "black",
                  fontSize: "13px",
                  marginRight: "8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >{`${row.zone_wire || "N/A"}`}</Typography>
              <Button
                size="large"
                style={{
                  backgroundColor: "#032C65",
                  border: "1px solid grey",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                  fontSize: "15px",
                  fontWeight: "normal",
                  color: "white",
                  borderRadius: "8px",
                }}
                startIcon={<EditIcon />}
                onClick={() => editZone(row)}
              >
                {t(`Edit`)}
              </Button>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );

  const TableZoneList = () => {
    const isSmallScreen = useMediaQuery("(max-width:800px)");

    const mapData = Object.keys(zones).map((key) => {
      const { decoder, zone_name, zone_wire, id, zone_type } = zones[key];

      return {
        id,
        decoder,
        zone_name,
        zone_wire,
        zone_type,
      };
    });

    return isSmallScreen ? (
      <MobileTable data={mapData} />
    ) : (
      <Grid container justifyContent="center">
        <Grid item xs={1} sm={12} md={10} lg={8}>
          <DataGrid
            style={{
              backgroundColor: "white",
              border: "2",
              borderRadius: "15px",
              boxShadow: "0 4px 8px rgba(5, 11, 0, 0.1)",
              fontSize: "15px",
              height: 700,
            }}
            hideFooter
            rows={zones}
            columns={columns}
            onSelectionModelChange={(newSelection) => {
              if (newSelection.selectionModel.length > 0) {
                const selectedRowIndex = newSelection.selectionModel[0];
                setSelectedRow(zones[selectedRowIndex]);
              }
            }}
            selectionModel={selectedRow ? [selectedRow.id] : []}
          />
        </Grid>
      </Grid>
    );
  };
  const saveChanges = async () => {
    let body_request = {
      zones: [
        {
          zone_number: selectedRow.id,
          name: formData.name,
          decoder: formData.decoder,
          wire: Number(formData.wire),
          zone_type: formData.Decodertype,
          assigned_pumps: [true, true, false, true, false],
        },
      ],
    };

    try {
      setloadingBox(true);

      const request = await apiUser.put(
        `/controllers/${location.state.id}/update_data/zones`,
        body_request
      );
      const index = zones.findIndex((zone) => zone.id === selectedRow.id);

      if (index !== -1) {
        const updatedZone = {
          ...zones[index],
          zone_name: formData.name,
          decoder: formData.decoder,
          zone_wire: formData.wire,
          zone_type: formData.Decodertype,
        };
        // console.log(updatedZone);
        const updatedZones = [...zones];
        updatedZones[index] = updatedZone;
        setZones(updatedZones);
      }
    } catch (e) {
      console.error(e);
      if (e) {
        Swal.fire({
          title: "Error",
          text: t("Error, has been occurred."),
          icon: "warning",
          timer: 2000,
        }).then(() => {
          navigate("/mycontrollers");
        });
      }
    }
    setloadingBox(false);
    setIsLoading(false);
    closebox();
  };

  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingBox}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Grid container item xs={12} justifyContent="center">
        <Grid
          sx={{
            borderRadius: "8px",
            border: `1px solid ${grey[300]}`,
            backgroundColor: "#F7F8FA",
            marginBottom: 4,
            padding: 2,
          }}
          container
          xs={10}
          justifyContent="start"
        >
          <Grid
            sx={{ borderRadius: "8px" }}
            item
            container
            xs={12}
            sm={2}
            component={Stack}
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              size="large"
              sx={{ backgroundColor: "white" }}
              onClick={() =>
                navigate(`/dashboard/${location.state.id}`, {
                  state: location.state,
                })
              }
            >
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid
            sx={{ borderRadius: "8px" }}
            item
            xs={12}
            sm={6}
            md={7}
            lg={8}
            alignItems="center"
            component={Stack}
          >
            <Typography variant="h5" fontWeight="bolder" color="#6B7A99">
              {t(`Zones`)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          {isLoading ? <SpinnerCircular /> : <TableZoneList />}
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={closebox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Grid container justifyContent="end">
            <CloseIcon
              size="large"
              color="primary"
              sx={{
                right: 5,
                top: "2%",
                cursor: "pointer",
              }}
              onClick={closebox}
            />
          </Grid>

          <div style={labelStyle}>
            {t(`Edit Zone`)}
            <br />
            <br />
          </div>

          <label
            style={{
              display: "block",

              color: "#032C65",
              fontWeight: "normal",
              marginBottom: "10px",
            }}
          >
            {t(`Name`)}:
          </label>
          <input
            type="text"
            placeholder={formData.name || ""}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            maxLength={20}
            style={{
              width: "95%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              textAlign: "center",
            }}
          />

          <label
            style={{
              display: "block",
              marginBottom: "10px",
              color: "#032C65",
              fontWeight: "normal",
              marginTop: "10px",
            }}
          >
           {t(`Decoder S/N`)}
          </label>
          <input
            placeholder={formData.decoder || ""}
            onChange={(e) =>
              setFormData({ ...formData, decoder: e.target.value })
            }
            maxLength={6}
            minLength={6}
            style={{
              width: "95%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              textAlign: "center",
            }}
          />
          <label
            style={{
              display: "block",
              marginBottom: "10px",
              color: "#032C65",
              fontWeight: "normal",
              marginTop: "10px",
            }}
          >
            {t(`Decoder type`)}:
          </label>
          <Select
            value={formData.Decodertype || 1}
            style={{
              width: "100%",
              padding: "1px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              textAlign: "center",
              height: "38px",
            }}
          >
            <MenuItem
              value={1}
              onClick={() => setFormData({ ...formData, Decodertype: 1 })}
            >
              {t(`Zone`)}
            </MenuItem>
            <MenuItem
              value={2}
              onClick={() => setFormData({ ...formData, Decodertype: 2 })}
            >
              MV/PS
            </MenuItem>
            {/* <MenuItem
              value={3}
              onClick={() => setFormData({ ...formData, Decodertype: 3 })}
            >
              Flow meter
            </MenuItem>
            <MenuItem
              value={4}
              onClick={() => setFormData({ ...formData, Decodertype: 4 })}
            >
              Moisture sensor
            </MenuItem> */}
          </Select>

          <label
            style={{
              display: "block",
              marginBottom: "10px",
              color: "#032C65",

              fontWeight: "normal",
              marginTop: "10px",
            }}
          >
            {t(`Wire`)}:
          </label>
          <Select
            value={formData.wire || "N/A"}
            onChange={(e) => setFormData({ ...formData, wire: e.target.value })}
            style={{
              width: "100%",
              padding: "1px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              textAlign: "center",
              height: "38px",
            }}
          >
            <MenuItem
              value="1"
              onClick={(e) =>
                setFormData({ ...formData, wire: e.target.value })
              }
            >
              {t(`TW 1`)}
            </MenuItem>
            <MenuItem
              value="2"
              onClick={(e) =>
                setFormData({ ...formData, wire: e.target.value })
              }
            >
              {t(`TW 2`)}
            </MenuItem>
          </Select>

          <LoadingButton
            variant="contained"
            loadingPosition="start"
            onClick={() => saveChanges()}
            loading={loadingBox}
            style={{
              marginTop: "20px",
              width: "50%",
              backgroundColor: "#032C65",
              color: "white",
              fontWeight: "normal",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
            }}
          >
            {t(`Confirm`)}
          </LoadingButton>
        </Box>
      </Modal>
    </>
  );
};

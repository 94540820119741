import {
  useState,
  useCallback,
  useEffect,
  createContext,
  useContext,
  useMemo,
} from "react";
import {
  signUp,
  signOut,
  signIn,
  fetchUserAttributes,
  resendSignUpCode,
  getCurrentUser,
  confirmSignUp,
  resetPassword,
  confirmResetPassword,
  fetchAuthSession,
} from "aws-amplify/auth";
import { Amplify } from "aws-amplify";

const awsConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export const UserContext = createContext(null);

export const UserProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginDetails, setLoginDetails] = useState(null);
  const [userAttributes, setUserAttributes] = useState(null);

  useEffect(() => {
    Amplify.configure(awsConfig);

    getCurrentUser()
      .then((user) => {
        setLoginDetails(user);
        setIsLoggedIn(true);
      })
      .catch(() => {
        setLoginDetails(null);
        setUserAttributes(null);
      });
  }, []);

  useEffect(() => {
    if (!!loginDetails) {
      fetchUserAttributes().then(async (userAttributes) => {
        setUserAttributes(userAttributes);
        setIsLoggedIn(true);
      });
    } else {
      setIsLoggedIn(false);
    }
  }, [loginDetails]);

  const logIn = useCallback(async (usernameOrEmail, password) => {
    try {
      const cognitoUser = await signIn({
        username: usernameOrEmail,
        password,
      });

      setLoginDetails(await getCurrentUser());

      setIsLoggedIn(true);
    } catch (err) {
      if (err.code === "UserNotFoundException") {
        err.message = "UserNotFoundException";
      }

      console.error(err);

      throw err;
    }
  }, []);

  const logOut = useCallback(async () => {
    try {
      await signOut();
      setUserAttributes(null);
      setLoginDetails(null);
      setIsLoggedIn(false);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const register = useCallback(
    async (firstName, lastName, companyName, username, email, password) => {
      try {
        const signUpResponse = await signUp({
          username,
          password,
          options: {
            userAttributes: {
              email,
              given_name: firstName,
              family_name: lastName,
              "custom:companyName": companyName,
              "custom:isMasterAccount": "true",
            },
          },
        });

        return true;
      } catch (err) {
        console.error(err);

        throw err;
      }
    },
    []
  );

  const confirmRegistration = useCallback(async (username, code) => {
    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username,
        confirmationCode: code,
      });

      return true;
    } catch (err) {
      console.error(err);

      throw err;
    }
  }, []);

  const resendRegisterCode = useCallback(async (username) => {
    try {
      const { isSignUpComplete, nextStep } = await resendSignUpCode({
        username,
      });
      // console.log(isSignUpComplete, nextStep);

      return true;
    } catch (err) {
      console.error(err);

      throw err;
    }
  }, []);

  const requestPasswordReset = useCallback(async (username) => {
    try {
      const pwResetResponse = await resetPassword({ username });
      // console.log("pw_reset", pwResetResponse);

      return true;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }, []);

  const confirmPasswordReset = useCallback(
    async (username, newPassword, confirmationCode) => {
      try {
        await confirmResetPassword({
          username,
          newPassword,
          confirmationCode,
        });

        return true;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    []
  );

  const getAuthTokens = async () => {
    const authSession = await fetchAuthSession();

    return {
      accessToken: authSession.tokens.accessToken.toString(),
      idToken: authSession.tokens.idToken.toString(),
    };
  };

  const authValues = useMemo(
    () => ({
      loginDetails,
      isLoggedIn,
      logIn,
      logOut,
      signUp: register,
      signUpConfirm: confirmRegistration,
      resendRegisterCode,
      getAuthTokens,
      resetPassword: requestPasswordReset,
      resetPasswordConfirm: confirmPasswordReset,
      userAttributes: !!userAttributes
        ? {
            id: userAttributes.sub,
            companyName: userAttributes["custom:companyName"],
            firstName: userAttributes.given_name,
            lastName: userAttributes.family_name,
            email: userAttributes.email,
            username: loginDetails.username,
            isMasterAccount:
              userAttributes["custom:isMasterAccount"] === "true",
            parentAccountId: userAttributes["custom:parentAccountId"],
          }
        : null,
    }),
    [loginDetails, userAttributes]
  );

  return (
    <UserContext.Provider value={authValues}>
      {props.children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("`useUser` must be used within a `UserProvider`");
  }

  return context;
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationsEN from "./translations/en.json"; 
import translationsES from "./translations/es.json";
import translationsZH from "./translations/zh.json";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng:'en',
    
    debug: true,
    supportedLngs: ['en', 'es', 'zh'],
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en:{...translationsEN},
      es: {...translationsES},
      zh: {...translationsZH},
    },
    detection: {
      order: ['navigator', 'localStorage', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage'],
      fallbackLng: 'en'
    },
  });

export default i18n;

import React, { createRef, useEffect, useState } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import SignupSchema from "./validations";
import { useFormik } from "formik";
import Modal from "@mui/material/Modal";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useUser } from "../../Auth/auth";
import sitemasterLogo from "./images/SitemasterLogo.png";
import leftSectionImage from "./images/Krainlogo-LeftSection.png";
import { MySwal } from "../../Components/helpers/SwalAlert";
import {
  Business,
  Close,
  Contacts,
  EmailOutlined,
  Lock,
  Person,
  PersonOutline,
} from "@mui/icons-material";
import { green } from "@mui/material/colors";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "white",
  border: "1px solid #032C65",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
  padding: "20px",
  textAlign: "center",
  height: "auto",
  objectFit: "contain",
};



const modalStyleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "30vh",
  bgcolor: "white",
  border: "1px solid grey",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
  padding: "20px",
  textAlign: "center",
  objectFit: "contain",
  overflow: "auto",
};

const SignInSteps = {
  SIGN_IN: 0,
  RESET_PASSWORD: 1,
  RESET_PASSWORD_CONFIRMATION: 2,
  SIGN_UP: 3,
  SIGN_UP_CONFIRMATION: 4,
};

const CodeInputs = ({ inputValues, handleInputChange }) => {
  const nInputs = 6;
  const [inputRefs] = useState(() =>
    Array.from({ length: nInputs }, () => createRef())
  );

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleKeyPress = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex < nInputs - 1 ? prevIndex + 1 : 0;
      const nextInput = inputRefs[nextIndex].current;
      nextInput.focus();
      nextInput.select();

      return nextIndex;
    });
  };

  return (
    <>
      {inputRefs.map((ref, index) => (
        <input
          ref={ref}
          key={`code-confirmation-input-${index}`}
          value={inputValues[index] || ""}
          style={{
            marginBottom: "10px",
            padding: "17px",
            width: "2%",
            textAlign: "center",
            borderRadius: "8px",
            marginRight: "3px",
            marginTop: "15px",
            objectFit: "contain",
          }}
          maxLength="1"
          onChange={(e) => {
            handleInputChange(index, e.target.value);
          }}
          onKeyUp={handleKeyPress}
          onClick={(e) => {
            setCurrentIndex(index);
            e.target.select();
          }}
        />
      ))}
    </>
  );
};

export const Signup = () => {
  const [open, setOpen] = useState(false);
  const [signInStep, setSignInStep] = useState(SignInSteps.SIGN_IN);
  const { signUp, signUpConfirm, resendRegisterCode } = useUser();
  const [inputValues, setInputValues] = useState(Array(6).fill(""));
  const [username, setusername] = useState("");
  const [running, setRunning] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [step, setStep] = useState(1);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      company_name: "",
      email: "",
      password: "",
      repeat_password: "",
      username: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      setRunning(true);

      try {
        localStorage.setItem("user", values.username);

        const signUpSuccess = await signUp(
          values.first_name,
          values.last_name,
          values.company_name,
          values.username,
          values.email,
          values.password
        );

        if (signUpSuccess) {
          setusername(values.username);
          setSignInStep(SignInSteps.SIGN_UP);
          setStep(1);
          setOpen(true);
        }
      } catch (err) {
        const parsedError = err.message
          .toLowerCase()
          .includes("emailalreadyregistered")
          ? t("Email already registered! Please try another one.")
          : null;

        Swal.fire({
          title: "Error",
          text: parsedError ? parsedError : err.message,
          icon: "error",
          confirmButtonColor: "#032C65",
          confirmButtonText: t("Got it"),
          background: "#f7f8fa",
        });
      } finally {
        setRunning(false);
      }
    },
  });

  const closeBox = () => {
    setOpen(false);
    setStep(1);
  };

  const confirmAcc = async () => {
    const concatenatedString = inputValues.join("");

    const user = localStorage.getItem("user");

    closeBox();
    setRunning(true);
    try {
      const confirmation = await signUpConfirm(user, concatenatedString);

      if (confirmation) {
        MySwal.fire({
          title:t( "Register completed"),
          text: t("Your account has been created"),
          icon: "success",
          timer: 2000,
        }).then(() => {
          setRunning(false);
          navigate("/login");
          closeBox();
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setRunning(false);
    }
  };

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  };

  const resendCode = async () => {
    let username = formik.values.username;
    localStorage.setItem("user", username);

    const user = username || localStorage.getItem("user");

    try {
      const request = await resendRegisterCode(user);

      setStep(1);
    } catch (e) {
      closeBox();
      MySwal.fire({
        title: "Oops!",
        text: t("Error, something went wrong"),
        icon: "error",
        timer: 1500,
      });
      console.error(e);
    }
  };

  const next = () => setStep(2);

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100vh",
          width: { xs: "100%", sm: "90%", md: "80%", lg: "70%" },
          margin: "auto",
        }}
      >
        {running && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={running}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          item
          container
          xs={11}
          sm={12}
          md={12}
          lg={9}
          justifyContent="center"
          component={Paper}
          elevation={7}
        >
          <Grid
            item
            xs={0}
            sm={6}
            sx={{
              display: { xs: "none", sm: "inherit" },
              backgroundColor: "#042C66",
              color: "white",
            }}
          >
            <img
              alt="Section"
              src={leftSectionImage}
              style={{ objectFit: "contain", width: "100%", height: "auto" }}
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            alignContent="center"
            xs={11}
            sm={6}
            sx={{ backgroundColor: "white", color: "#18355E" }}
          >
            <Grid item xs={10} marginBottom={5}>
              <Grid
                item
                xs={12}
                display={{
                  xs: "block",
                  sm: "none",
                  md: "none",
                  lg: "none",
                  xl: "none",
                }}
              >
                <img
                  alt="Section"
                  src={sitemasterLogo}
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "auto",
                    marginBottom: "15%",
                  }}
                />
              </Grid>
              <Typography
                variant="h4"
                fontWeight="bold"
                textAlign="start"
                marginLeft="10%"
              >
                {t(`Create Your Account`)}
              </Typography>
            </Grid>
            <Grid item container xs={10} justifyContent="space-between">
              <Grid item xs={12} md={5.6}>
                <TextField
                  id="first_name"
                  label={t("First name")}
                  name="first_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                  variant="outlined"
                  placeholder="John"
                  error={formik.touched.first_name && formik.errors.first_name}
                  helperText={
                    formik.touched.first_name && formik.errors.first_name
                      ? formik.errors.first_name
                      : ""
                  }
                  sx={{ width: "100%", marginBottom: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5.6}>
                <TextField
                  id="last_name"
                  label={t("Last name")}
                  name="last_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.last_name}
                  variant="outlined"
                  placeholder="Doe"
                  error={formik.touched.last_name && formik.errors.last_name}
                  helperText={
                    formik.touched.last_name && formik.errors.last_name
                      ? formik.errors.last_name
                      : ""
                  }
                  sx={{ width: "100%", marginBottom: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PersonOutline />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <TextField
                id="username"
                label={t("Username")}
                name="username"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
                placeholder="_dutchman_flying_112"
                variant="outlined"
                sx={{ width: "100%", marginBottom: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Contacts />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                id="email-input"
                label={t("Email")}
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                placeholder="johndoe@gmail.com"
                error={formik.touched.email && formik.errors.email}
                helperText={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : ""
                }
                variant="outlined"
                sx={{ width: "100%", marginBottom: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                id="email-input"
                label={t("Company name")}
                name="company_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.company_name}
                variant="outlined"
                error={
                  formik.touched.company_name && formik.errors.company_name
                }
                helperText={
                  formik.touched.company_name && formik.errors.company_name
                    ? formik.errors.company_name
                    : ""
                }
                sx={{ width: "100%", marginBottom: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Business />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              container
              xs={10}
              justifyContent="space-between"
              marginBottom={3}
            >
              <Grid item xs={12} md={5.6}>
                <TextField
                  id="password"
                  name="password"
                  label={t("Password")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  variant="outlined"
                  error={formik.touched.password && formik.errors.password}
                  helperText={
                    formik.touched.password && formik.errors.password
                      ? formik.errors.password
                      : ""
                  }
                  type="password"
                  sx={{ width: "100%", marginBottom: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Lock />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5.6}>
                <TextField
                  id="repeat_password"
                  label={t("Confirm Password")}
                  name="repeat_password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.repeat_password}
                  variant="outlined"
                  error={
                    formik.touched.repeat_password &&
                    formik.errors.repeat_password
                  }
                  helperText={
                    formik.touched.repeat_password &&
                    formik.errors.repeat_password
                      ? formik.errors.repeat_password
                      : ""
                  }
                  type="password"
                  sx={{ width: "100%", marginBottom: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Lock />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={10} justifyContent="center">
              <Button
                variant="contained"
                onClick={() => formik.handleSubmit()}
                sx={{
                  width: "100%",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  backgroundColor: green[600],
                  paddingY: 1,
                }}
                type="submit"
              >
                {t(`Sign Up`)}
              </Button>
              <Grid
                item
                container
                xs={10}
                marginTop={2}
                color="#18355E"
                justifyContent="space-evenly"
              >
                <Typography>
                  {t(`Already a member?`)} &nbsp;
                  <Link
                    to="/login"
                    style={{
                      color: green[600],
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    {t(`Sign In`)}
                  </Link>
                </Typography>
                <Typography>
                  <Link
                    onClick={() => {
                      setOpen(true);
                    }}
                    style={{
                      marginLeft: 15,
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    {t(`Already have a code ?`)}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={closeBox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSmallScreen ? (
          <Box sx={modalStyle}>
            <Grid container justifyContent="end" padding={1}>
              <Close
                size="large"
                color="primary"
                sx={{
                  right: 6,
                  top: "1%",
                  cursor: "pointer",
                }}
                onClick={closeBox}
              />
            </Grid>
            <img
              className="logo-section"
              alt="Section"
              style={{ width: "100%", maxWidth: "250px", marginBottom: "20px" }}
              src={sitemasterLogo}
            />

            {step === 1 && (
              <>
                <Typography
                  style={{
                    textAlign: "center",
                    color: "#032C65",
                    fontSize: "22px",
                    fontWeight: "normal",
                    marginTop: "25px",
                  }}
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                >
                  {t(`Type your confirmation code.`)}
                </Typography>
                <CodeInputs
                  inputValues={inputValues}
                  handleInputChange={handleInputChange}
                />
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={running}
                  onClick={confirmAcc}
                  style={{
                    marginTop: "20px",
                    top: "10px",
                    width: "50%",
                    backgroundColor: "#032C65",
                    color: "white",
                    fontWeight: "normal",
                    border: "1px solid grey",
                    boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#032C65",
                    },
                  }}
                >
                  {t(`Confirm`)}
                </LoadingButton>
                <LoadingButton
                  style={{
                    marginTop: "20px",
                    top: "10%",
                    left: "15%",
                    position: "relative",
                  }}
                  onClick={() => setStep(2)}
                  className={"log-in-text-wrapper-4"}
                >
                  {t(`Resend code`)}
                </LoadingButton>
              </>
            )}

            {step === 2 && (
              <>
                <label
                  style={{
                    display: "block",
                    marginBottom: "15px",
                    color: "black",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >

                  {t(`Introduce Username`)}


                </label>
                <input
                  type="text"
                  placeholder=" Username "
                  id="username"
                  name="username"
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    color: "black",
                    textAlign: "center",
                    width: "100%",
                    height: "35px",
                    border: "1px solid grey",
                    boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                    fontWeight: "normal",
                    fontSize: "18px",
                    borderRadius: "8px",
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                />
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={running}
                  onClick={resendCode}
                  style={{
                    marginTop: "20px",
                    top: "10px",
                    width: "50%",
                    backgroundColor: "#032C65",
                    color: "white",
                    fontWeight: "normal",
                    border: "1px solid grey",
                    boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                  }}
                >
                  Resend code
                </LoadingButton>
              </>
            )}
          </Box>
        ) : (
          <Box sx={modalStyleMobile}>
            <Grid container justifyContent="end" padding={1}>
              <Close
                size="large"
                color="primary"
                sx={{
                  right: 6,
                  top: "1%",
                  cursor: "pointer",
                }}
                onClick={closeBox}
              />
            </Grid>
            <img
              className="logo-section"
              alt="Section"
              style={{ width: "100%", maxWidth: "250px", marginBottom: "20px" }}
              src={sitemasterLogo}
            />

            {step === 2 && (
              <>
                <>
                  <>
                    <label
                      style={{
                        display: "block",
                        marginBottom: "15px",
                        color: "black",
                        fontWeight: "normal",
                        textAlign: "center",
                      }}
                    >
                      {t(`Introduce username`)}
                    </label>
                    <input
                      type="text"
                      placeholder="username"
                      id="username"
                      name="username"
                      style={{
                        display: "block",
                        marginBottom: "10px",
                        marginLeft: "10%",
                        color: "black",
                        textAlign: "center",
                        width: "80%",
                        height: "35px",
                        fontWeight: "normal",
                        fontSize: "18px",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                      }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.username}
                    />
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={running}
                      onClick={() => resendCode()}
                      style={{
                        marginTop: "20px",
                        top: "10px",
                        width: "50%",
                        backgroundColor: "#032C65",
                        color: "white",
                        fontWeight: "normal",
                        border: "1px solid grey",
                        boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                      }}
                    >
                      {t(`Resend code`)}
                    </LoadingButton>
                  </>
                </>
              </>
            )}

            {step === 1 && (
              <>
                <Typography
                  style={{
                    textAlign: "center",
                    color: "#032C65",
                    fontSize: "22px",
                    fontWeight: "normal",
                    marginTop: "5px",
                  }}
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                >
                  {t(`Submit your confirmation code.`)}
                </Typography>
                <CodeInputs
                  inputValues={inputValues}
                  handleInputChange={handleInputChange}
                />
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={running}
                  onClick={confirmAcc}
                  style={{
                    marginTop: "20px",
                    top: "10px",
                    width: "50%",
                    backgroundColor: "#032C65",
                    left: "10%",
                    color: "white",
                    fontWeight: "normal",
                    border: "1px solid grey",
                    boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#032C65",
                    },
                  }}
                >
                  {t(`Confirm`)}
                </LoadingButton>
                <LoadingButton
                  style={{
                    top: "7%",
                    left: "15%",
                    position: "relative",
                  }}
                  onClick={() => setStep(2)}
                  className={"log-in-text-wrapper-4"}
                >
                  {t(`Resend code`)}
                </LoadingButton>
              </>
            )}
          </Box>
        )}
      </Modal>
    </>
  );
};

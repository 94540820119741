import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

//LOGOS LIST
import sitemasterlogo from "./SitemasterLogo.png";

// Buttons &  icons materials
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import MapIcon from "@mui/icons-material/Map";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useEffect, useState } from "react";
import { useUser } from "../Auth/auth";
import { MySwal } from "./helpers/SwalAlert";
import {
  AccountCircle,
  AppsSharp,
  EditCalendar,
  EditLocation,
  GroupWork,
  Home,
  ManageAccounts,
} from "@mui/icons-material";
import { blueGrey, grey } from "@mui/material/colors";
import { Grid, Menu, MenuItem, useMediaQuery } from "@mui/material";
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
export function Navbar({ dash, children, ...otherProps }) {
  let navigate = useNavigate();
  const theme = useTheme();
  let location = useLocation();
  const { logOut } = useUser();
  const isSmallScreen = useMediaQuery("(max-width:1024px)");
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t, i18n } = useTranslation();
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null); // Estado para el ancla del menú de idiomas
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false); // Estado para controlar la apertura del menú de idiomas
  useEffect(() => {
    checkAndNavigate();

    if (location.pathname.indexOf("dashboard")) {
      setOpen(false);
    }
  }, [location.state, navigate]);

  const logout = () => {
    const request = logOut();

    if (request) {
      MySwal.fire({
        title: t("Log Out"),
        text: t("Closing user session..."),
        timer: 1500,
        icon: "info",
        confirmButtonColor: "#032C65",
        confirmButtonText: t("Confirm"),
        background: "#f7f8fa",
      }).then(() => {
        navigate("/login");
      });
    }
  };

  const changeLanguage = (lng) => {

    i18n.changeLanguage(lng)

    handleCloseLanguageMenu()

  }

  const handleOpenLanguageMenu = (event) => {
    setLanguageAnchorEl(event.currentTarget);
    setLanguageMenuOpen(true);
  };

  const handleCloseLanguageMenu = () => {
    setLanguageAnchorEl(null);
    setLanguageMenuOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const checkAndNavigate = () => {
    if (
      !location.state &&
      location.pathname !== "/mycontrollers" &&
      location.pathname !== "/subAcc" &&
      location.pathname !== "/SignUp" &&
      location.pathname !== "/login" &&
      location.pathname !== "/mainAcc"
    ) {
      MySwal.fire({
        title: t("Cant access without id controller "),
        text: t(`Navigating to your controllers...
        `),
        timer: 3000,
        timerProgressBar: true,

        icon: "warning",
        confirmButtonColor: "#032C65",
        confirmButtonText: "Confirm",
        background: "#f7f8fa",
      }).then(() => {
        navigate("/mycontrollers");
      });
    }
  };

  // if (withoutNavbar.some((path) => location.pathname.includes(path)))
  //   return <></>;
  const navigationItems = [
    {
      basePath: "mycontrollers",
      label: t("Controllers"),
      icon: Home,
    },
    {
      basePath: "dashboard",
      label: t("Dashboard"),
      icon: AssignmentIcon,
    },
    {
      basePath: "programs",
      label: t("Programs"),
      icon: EditCalendar,
    },
    {
      basePath: "zone",
      label: t("Zones"),
      icon: EditLocation,
    },
    {
      basePath: "map_zone",
      label: t("Map"),
      icon: MapIcon,
    },
    {
      basePath: "zone_grouping",
      label: t("Zone Grouping"),
      icon: GroupWork,
    },
  ];

  const NavMenuItemsList = (props) => {
    const { isDrawer } = props;
    return (
      <List
        key={isDrawer ? "drawer-items" : "other-items"}
        sx={
          isDrawer
            ? {
                width: "100%",
                backgroundColor: "white",
                maxWidth: 360,
                fontWeight: "bold",
                borderRadius: "18px",
              }
            : {
                width: "100%",
                backgroundColor: "#ECEFF1",
                maxWidth: 360,
                fontWeight: "bold",
                borderRadius: "18px",
                border: `1px solid ${grey[300]}`,
                padding: 1.5,
              }
        }
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={<ListSubheader component="div" id="nested-list-subheader" />}
      >
        {navigationItems
          .map((navItem, idx) => {
            const pathIsItem = location.pathname.startsWith(
              `/${navItem.basePath}/`
            );
            const pathIsControllerSpecific =
              !location.pathname.startsWith("mycontrollers");
            const itemIsDashboard = navItem.basePath === "dashboard";

            if (itemIsDashboard && pathIsItem) {
              return null;
            }

            return (
              <>
                <ListItemButton
                  key={`nav-item-${idx}`}
                  onClick={() =>
                    pathIsControllerSpecific &&
                    location.state !== null &&
                    location.state.id !== null
                      ? navigate(`${navItem.basePath}/${location.state.id}`, {
                          state: location.state,
                        })
                      : navigate("/mycontrollers")
                  }
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: pathIsItem ? "#212C63" : "white",
                    marginBottom: isDrawer ? 0 : 1.5,
                    padding: 2,
                    borderRadius: isDrawer ? 0 : "18px",
                    boxShadow: "0px 1px 1px 1px rgba(0,0,0,0.07)",
                  }}
                >
                  <ListItemIcon>
                    <navItem.icon
                      sx={{
                        color: pathIsItem ? "#8296B2" : "#212C63",
                        fontSize: "1.7em",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={navItem.label}
                    primaryTypographyProps={{
                      color: pathIsItem ? "white" : "#8296B2",
                      fontWeight: "bolder",
                    }}
                  />
                </ListItemButton>
                {isDrawer && <Divider key={`divider-${idx}`} />}
              </>
            );
          })
          .filter((item) => !!item)}
      </List>
    );
  };

  return (
    <Box sx={{ display: "flexGrow", paddingBottom: 3 }}>
      <AppBar
        position="static"
        open={open}
        component="nav"
        sx={{ marginBottom: 2 }}
        elevation={0}
      >
        <Toolbar
          sx={{
            backgroundColor: blueGrey[50],
            display: "flex",
            direction: "row",
            justifyContent: "space-between",
          }}
        >
          {open ? (
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                backgroundColor: blueGrey[400],
              }}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          ) : (
            location.pathname !== "/mycontrollers" && (
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                sx={{
                  backgroundColor: "white",
                  display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
                }}
              >
                <AppsSharp
                  sx={{
                    color: "#212C64",
                    "&:hover": { color: blueGrey[700] },
                  }}
                />
              </IconButton>
            )
          )}
          <div style={{ flexGrow: 1, paddingLeft: "1%", paddingRight: "1%" }}>
            <img
              className="logo-section"
              alt="Section"
              style={{ width: "100%", maxWidth: "250px" }}
              src={sitemasterlogo}
            />
          </div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <AccountCircle fontSize="large" sx={{ color: "#212C64" }} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                navigate(`/mainAcc/`, {
                  state: {
                    id: location.state !== null ? location.state.id : null,
                  },
                });
              }}
            >
              <ListItemIcon>
                <ManageAccounts color="action" />
              </ListItemIcon>
              <ListItemText>{t(`Profile`)}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                logout();
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon color="error" />
              </ListItemIcon>
              <ListItemText>{t(`Log Out`)}</ListItemText>
            </MenuItem>
            <MenuItem
            onClick={handleOpenLanguageMenu}
            >
            <ListItemIcon>
              <LanguageIcon />
            </ListItemIcon>
            <ListItemText>{t(`Change language`)}</ListItemText>
            </MenuItem>
            

          
          <Menu
            id="language-menu"
            anchorEl={languageAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={languageMenuOpen}
            onClose={handleCloseLanguageMenu}
          >
            <MenuItem onClick={() => changeLanguage("en")}>English</MenuItem>
            <MenuItem onClick={() => changeLanguage("es")}>Español</MenuItem>
            <MenuItem onClick={() => changeLanguage("zh")}>中文</MenuItem>
          </Menu>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        open={open && location.pathname !== "/mycontrollers"}
        onClose={() => setOpen(false)}
      >
        <Divider />
        <Box
          sx={{ width: 300 }}
          role="presentation"
          onClick={() => setOpen(false)}
        >
          <NavMenuItemsList key="drawer-menu-items" isDrawer />
        </Box>
      </Drawer>
      <Grid container justifyContent="center">
        {location.pathname !== "/mycontrollers" && (
          <Grid
            item
            md={3}
            lg={2.2}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "inline-block",
                lg: "inline-block",
              },
              paddingX: 1,
            }}
          >
            <NavMenuItemsList key="grid-menu-items" />
          </Grid>
        )}
        <Grid item xs={12} md={9} lg={9.8}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}

import React from "react";
import { ZoneGroupingTable } from "./ZoneGroupingTable";
import { Grid } from "@mui/material";

export const Zone_grouping = () => {
  return (
    <Grid container>
      <ZoneGroupingTable />
    </Grid>
  );
};

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Programs.css";
import { ProgramsEditView } from "./ProgramsEditView";
import { apiUser } from "../../Apis/configs/axiosConfig";
import { SpinnerCircular } from "spinners-react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
  Paper,
  Stack,
  IconButton,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import CableIcon from "@mui/icons-material/Cable";
import EditIcon from "@mui/icons-material/Edit";
import { blueGrey, green, grey, red } from "@mui/material/colors";
import { ArrowBack, ExpandMore } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
export const ProgramsTable = () => {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [programs, setPrograms] = useState([]);
  const location = useLocation();
  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getPrograms();
  }, [location.pathname]);

  const getPrograms = async () => {
    setIsLoading(true);

    try {
      const resp2 = await apiUser.get(`/controllers/${location.state.id}`);

      setPrograms(resp2.data.data.programs);

      // console.log(resp2.data.data.programs);
    } catch (e) {
      console.error("Error : ", e);
    }

    setIsLoading(false);
  };

  const edit_button = (program) => {
    if (program) {
      setShow(!show);
      location.state.program = {
        id: t(`Program`) + `${program.letter.toUpperCase()}`,
        letter: program.letter,
        ...programs[program.letter],
      };
    }
  };

  const columns = [
    {
      field: "id",
      headerName: t("Program"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      align: "left",
      headerClassName: "header-table-title-style",
    },
    {
      field: "watering_days",
      headerName: t("Watering Days"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
    },
    {
      field: "start_times",
      headerName: t("Start Times"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
      renderCell: (params) => {
        return (
          <ul style={{ fontSize: 13 }}>
            {params.row.start_times.map((item, index) => (
              <li key={`st-item-${index}`}>{item}</li>
            ))}
          </ul>
        );
      },
    },
    {
      field: "RunTimes_Zones",
      headerName: t("Connected Zones"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
      renderCell: (params) => {
        const numberOfZones = params.row.run_times.reduce((count, time) => {
          if (time !== "0:0:0") {
            return count + 1;
          }
          return count;
        }, 0);

        const basicStyles = {
          borderRadius: 15,
          fontSize: 12,
          fontWeight: "bolder",
          display: "inline",
        };

        return (
          <Paper
            sx={{
              ...basicStyles,
              color: "white",
              backgroundColor: numberOfZones > 0 ? green[500] : red[500],
              paddingX: 2,
              paddingY: 0.3,
              marginY: 1,
            }}
          >
            {t(`Zones`)}
            <Chip
              label={`${numberOfZones}/99`}
              sx={{
                fontWeight: "bolder",
                marginLeft: 2,
                backgroundColor: "white",
              }}
            />
          </Paper>
        );
      },
    },
    {
      flex: 1,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
      sortable: false,
      renderCell: ({ row }) => (
        <Button
          type="button"
          size="large"
          style={{
            borderRadius: "8px",
            fontSize: "15px",
            fontWeight: "normal",
            color: "#032C65",
          }}
          startIcon={<EditIcon />}
          onClick={() => edit_button(row)}
        ></Button>
      ),
    },
  ];

  const MobileTable = ({ data }) => (
    <Grid item container xs={12} justifyContent="center">
      {data.map((row, index) => (
        <Grid item xs={9} key={`mobile-table-item-${index}`}>
          <Accordion
            style={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid grey",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
              marginBottom: "16px",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Button
                  variant="contained"
                  size="medium"
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    marginLeft: "8px",
                    marginBottom: "8px",
                    fontWeight: "normal",
                  }}
                  startIcon={<CableIcon />}
                >
                  {row.id.toUpperCase()}
                </Button>
              </div>
            </AccordionSummary>
            <AccordionDetails
              style={{
                color: "#032C65",
                fontWeight: "normal",
                marginRight: "8px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              <Typography
                style={{
                  color: "#032C65",
                  fontWeight: "normal",
                  marginRight: "8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Watering Days:
              </Typography>
              <Typography
                style={{
                  color: "black",
                  fontSize: "13px",
                  marginRight: "8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >{`${row.watering_days || "N/A"}`}</Typography>

              <Typography
                style={{
                  color: "#032C65",
                  fontWeight: "normal",
                  marginRight: "8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                Start Times:
              </Typography>
              <Typography
                style={{
                  color: row.start_times.length > 0 ? "black" : red[500],
                  fontSize: "13px",
                  marginRight: "8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                {row.start_times.length > 0
                  ? row.start_times.join(", ")
                  : "N/A"}
              </Typography>
            </AccordionDetails>
            <AccordionDetails
              style={{
                textAlign: "justify",
                color: "#032C65",
                padding: "16px",
                borderTop: "1px solid #ccc",
                marginTop: "8px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="button"
                size="large"
                style={{
                  backgroundColor: "yellowgreen",
                  border: "1px solid black",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                  fontSize: "15px",
                  fontWeight: "normal",
                  color: "#032C65",
                }}
                startIcon={<EditIcon />}
                onClick={() => edit_button(row)}
              >
                Edit
              </Button>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );

  const TableControllersList = () => {
    const isSmallScreen = useMediaQuery("(max-width:800px)");

    const mapData = Object.keys(programs).map((key) => {
      const { run_times, start_times, watering_days } = programs[key];

      const formattedWateringDays = watering_days
        ? {
            count_days:
              watering_days.count_days > 0 ? watering_days.count_days : 0,
            days_of_week: watering_days.days_of_week
              ? watering_days.days_of_week
                  .filter((day) => day.active)
                  .map((day) => t(day.day_of_week.name).slice(0, 3))
              : [],
            even: watering_days.even,
            odd: watering_days.odd,
            warning: watering_days.warning,
          }
        : {};

      const w_days = Object.keys(formattedWateringDays)
        .map((prop) => {
          const value = formattedWateringDays[prop];
          
          if (prop === "count_days" && value > 0) {
            return `Cycle Days: ${value}`;
          } else if (prop === "odd" && value) {
            return "Odd Days";
          } else if (prop === "even" && value) {
            return "Even Days";
          } else if (prop === "days_of_week" && value.length > 0) {
            return  t(`${value.join(", ")}`)
          }

          return null;
        })
        .filter((value) => value !== null);

      const formatStartTime = (startTimeObject) => {
        const { active, start_time } = startTimeObject;

        const isPm = start_time.hour > 12;
        const finalHour =
          start_time.hour > 12 ? start_time.hour - 12 : start_time.hour;
        const mins = start_time.minutes;

        return active
          ? `${(finalHour === 0 ? 12 : finalHour)
              .toString()
              .padStart(2, "0")}:${mins.toString().padStart(2, "0")} ${
              isPm ? t("PM") : t("AM")
            }`
          : null;
      };

      const formattedStartTimes = start_times
        .map(formatStartTime)
        .filter(Boolean);

      return {
        id: t(`Program`) + ' ' + `${ key.toUpperCase()}`,
        letter: key.toLowerCase(),
        watering_days: w_days.join(", "),
        run_times: run_times.map(
          (time) => `${time.hour}:${time.minutes}:${time.seconds}`
        ),
        start_times: formattedStartTimes.length > 0 ? formattedStartTimes : [],
      };
    });

    return (
      <>
        {isSmallScreen ? (
          <MobileTable data={mapData} />
        ) : (
          <Grid item container xs={10} justifyContent="center">
            <DataGrid
              rows={mapData}
              columns={columns}
              hideFooter
              getRowHeight={() => "auto"}
              sx={{
                backgroundColor: "white",
                borderRadius: "15px",
                boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                fontSize: "15px",
              }}
              onSelectionModelChange={(newSelection) => {
                if (newSelection.selectionModel.length > 0) {
                  const selectedRowIndex = newSelection.selectionModel[0];
                  setSelectedRow(mapData[selectedRowIndex]);
                }
              }}
              selectionModel={selectedRow ? [selectedRow.id] : []}
            />
          </Grid>
        )}
      </>
    );
  };

  return (
    <Grid container justifyContent="center">
      {location.state.program && show && (
        <Grid item container xs={10} justifyItems="start" paddingTop={2}>
          <Button
            size="large"
            sx={{
              backgroundColor: "white",
              borderRadius: 3,
              color: blueGrey[600],
              marginBottom: 2,
            }}
            onClick={() => {
              location.state.program = null;
              setShow(false);
              getPrograms();
            }}
            startIcon={<ArrowBack />}
          >
            {t(`Back to all programs`)}
          </Button>
        </Grid>
      )}
      {!location.state.program && (
        <Grid
          sx={{
            borderRadius: "8px",
            border: `1px solid ${grey[300]}`,
            backgroundColor: "#F7F8FA",
            padding: 2,
            marginBottom: 4,
          }}
          item
          container
          component={Paper}
          elevation={0}
          xs={10}
        >
          <Grid
            sx={{ borderRadius: "8px" }}
            item
            xs={12}
            sm={2}
            alignItems="center"
            component={Stack}
            justifyContent="center"
          >
            <IconButton
              size="large"
              sx={{ backgroundColor: "white" }}
              onClick={() => {
                const newState = location.state;
                newState.program = null;

                navigate(`/dashboard/${location.state.id}`, {
                  state: newState,
                });
              }}
            >
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid
            sx={{ borderRadius: "8px" }}
            item
            xs={12}
            sm={6}
            md={7}
            lg={8}
            alignItems="center"
            component={Stack}
          >
            <Typography variant="h5" fontWeight="bolder" color="#6B7A99">
              {t(`Programs`)}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid item container xs={12} justifyContent="center">
        {!show ? (
          isLoading ? (
            <SpinnerCircular />
          ) : (
            <TableControllersList />
          )
        ) : (
          <ProgramsEditView />
        )}
      </Grid>
    </Grid>
  );
};
